import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { store } from './Redux/store';
import { SocketContext, socket } from './Context/socket';
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react';
import { Tooltip } from 'react-tooltip';

let persistor = persistStore(store);

const rootDom = document.getElementById('root');
const root = ReactDOM.createRoot(rootDom);
// root.render(
//   // <React.StrictMode>
//     <Provider store={store}>
//     <PersistGate loading={<>Loading...</>} persistor={persistor}>
//     <SocketContext.Provider value={socket}>
//       <App />
//       </SocketContext.Provider>
//       <Tooltip id="my-tooltip" style={{ maxWidth: '300px', wordWrap: 'break-word' }}/> 
//     </PersistGate>
//     </Provider>
//   // </React.StrictMode>
// );

if (rootDom.hasChildNodes()) {
  root.hydrate(<Provider store={store}>
    <PersistGate loading={<>Loading...</>} persistor={persistor}>
      <SocketContext.Provider value={socket}>
        <App />
      </SocketContext.Provider>
      <Tooltip id="my-tooltip" style={{ maxWidth: '300px', wordWrap: 'break-word' }} />
    </PersistGate>
  </Provider>, root);
} else {
  root.render(<Provider store={store}>
    <PersistGate loading={<>Loading...</>} persistor={persistor}>
      <SocketContext.Provider value={socket}>
        <App />
      </SocketContext.Provider>
      <Tooltip id="my-tooltip" style={{ maxWidth: '300px', wordWrap: 'break-word' }} />
    </PersistGate>
  </Provider>, root);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
