import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import BattleCard from "../BattelCard/BattleCard";
import TrackPlayer from "../TrackPlayer/TrackPlayer";
import "./ViewAll.scss";
import { useLocation, useNavigate } from "react-router-dom";
import * as cs from "../../Api/cs";
import useHeader from "../../Helper/useHeader";
import { useDispatch, useSelector } from "react-redux";
import { updateBattleId } from "../../Redux/Slices/battle.slice";
import { SwiperSlide } from "swiper/react";

import Artist from "../Artist/Artist";
import GenreCard from "../GenreCard/GenreCard";
import { BallTriangle } from "react-loader-spinner";

const ViewAll = () => {
  const location = useLocation();
  const { type, title, genre, artistId, trackId } = location.state;

  const headers = useHeader();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [trending, setTrending] = useState(false);
  const [trendingBattle, setTrendingBattle] = useState([]);
  const [battleList, setBattleList] = useState([]);
  const [trackList, setTrackList] = useState([]);
  const [artist, setArtist] = useState([]);
  const [leaderboard, setLeaderBoard] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  // const [totalpages,setTotalpages]= useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const { track, isPlaying } = useSelector((state) => state.player);
  const loader = useRef(null);
  const loadMoreData = () => {
    // Fetch more data here
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadMoreData();
        }
      });
    }, options);

    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, []);
  useEffect(() => {
    if (
      battleList?.length >= totalRecords ||
      trackList?.length >= totalRecords ||
      artist?.length >= totalRecords ||
      leaderboard?.length >= totalRecords ||
      trendingBattle?.length >= totalRecords
    ) {
      return; // Exit early if all data has been fetched
    }
    if (title == "New Battles" && type == "battle") {
      setLoading(true)
      if (genre == null) {
        getNewBattles();
        setLoading(false)
      } else if (genre != null) {
        getNewBattleOfGenre();
        setLoading(false)

      }
    } else if (title == "Ending Soon" && type == "battle") {
      setLoading(true)
      if (genre == null) {
        getEndingBattle();
        setLoading(false)
      } else if (genre != null) {
        getEndingBattleOfGenre();
        setLoading(false)

      }
    } else if (title == "Join A Battle" && type == "battle") {
      setLoading(true)
      if (genre == null) {
        getAwaitingBattle();
        setLoading(false)
      } else if (genre != null) {
        getAwaitingBattleOfGenre();
        setLoading(false)

      }
    } else if (title == "Trending Battle" && type == "trendingBattle") {
      setLoading(true)
      if (genre == null) {
        getTrendingBattle();
        setLoading(true)
      } else if (genre != null) {
        getTrendingBattlesOfGenre();
        setLoading(true)

      }
    }
    else if (title == "Recommended Battles" && type == "battle") {
      setLoading(true)
      if (genre == null) {
        getRecommendedBattles();
        setLoading(false)

      }
    } else if (title == "Trending Beats" && type == "track") {
      setLoading(true)
      if (genre == null) {
        getTrendingTracks();
        setLoading(false)
      }
    }
    // else if (title.includes("Battles") && type == "battle") {
    //   setLoading(true)
    //   if (genre == null) {
    //     getBattleList();
    //     setLoading(false)
    //   }
    // } 
    else if (title == "Popular Artists" && type == "artist") {
      setLoading(true)
      if (genre == null) {
        getPopularArtist();
        setLoading(false)

      } else if (genre != null) {
        getPopularArtistOfGenre();
        setLoading(false)
      }
    }
    else if (title == "Trending Battles" && type == "trendingBattle") {
      setLoading(true)
      if (genre == null) {
        getTrendingBattles();
        setLoading(true)
      }
    }
    else if (title == "Battle Leaderboards" && type == "leaderboard") {
      setLoading(true)
      if (genre == null) {
        getLeaderBoard();
        setLoading(false)

      }
    } else if (title == "Battles" && type == "battle") {
      setLoading(true)
      getBattleListOfArtist();
      setLoading(false)

    }
  }, [page]);



  const getNewBattles = async () => {
    // const response = await cs.get('all/' + 'new', 'battel', {}, headers)
    try {
      const response = await cs.get(
        `all/new?page=${page}`,
        "battel",
        {},
        headers
      );
      console.log('responseeeeeeee', response)

      setBattleList((prevList) => [...prevList, ...response?.data?.data]);
      setTotalRecords(response?.data?.totalRecords);
    } catch (err) {
      console.log('responseeeeeeee err', err)

    }
  };

  const getEndingBattle = async () => {
    const response = await cs.get(
      `all/end?page=${page}`,
      "battel",
      {},
      headers
    );

    setBattleList((prevList) => [...prevList, ...response?.data?.data]);
    setTotalRecords(response?.data?.totalRecords);
    // setEndingBattle(response?.data?.data)
  };
  const getTrendingBattle = async () => {
    const response = await cs.get("trendingBattles", "battel", null, headers);

    setTrendingBattle(response?.data?.data);
    setTotalRecords(response?.data?.totalRecords);
    setTrending(true);
  };

  const getTrendingBattlesOfGenre = async () => {
    const response = await cs.get(
      "trendingBattles/" + genre,
      "genre",
      {},
      headers
    );

    setTrendingBattle(response?.data?.data);
    setTotalRecords(response?.data?.totalRecords);
  };
  const getAwaitingBattle = async () => {
    const response = await cs.get(
      `still/notjoin?page=${page}`,
      "battel",
      null,
      headers
    );

    setBattleList((prevList) => [...prevList, ...response?.data?.data]);
    setTotalRecords(response?.data?.totalRecords);
  };
  const getPopularArtist = async () => {
    const response = await cs.get("popular/artists", "track", null, headers);

    setArtist(response?.data?.data);
    setTotalRecords(response?.data?.totalRecords);
    // const artists = response?.data?.data.map(item => item.artist);
    // setArtist(response?.data?.data.map(item => item.artist).flat());
  };
  const getPopularArtistOfGenre = async () => {
    const response = await cs.get(
      "popularArtists/" + genre,
      "genre",
      {},
      headers
    );

    setArtist(response?.data?.data);
    setTotalRecords(response?.data?.totalRecords);
  };

  const getLeaderBoard = async () => {
    const response = await cs.get("allTopWinner", "leaderboard", null, headers);
    setLeaderBoard(response?.data?.data);
    setTotalRecords(response?.data?.totalRecords);
  };

  const getTrendingTracks = async () => {
    const response = await cs.get(
      `all/countList?page=${page}`,
      "track",
      null,
      headers
    );

    console.log('getTrendingTracks response', response?.data?.data)
    setTrackList((prevList) => [...prevList, ...response?.data?.data]);
    // setTotalPages(response?.data?.totalPages);
    setTotalRecords(response?.data?.totalRecords);
  };

  const getBattleListOfArtist = async () => {
    const response = await cs.get(
      `getAllBattlesOfArtist/${artistId}?page=${page}`,
      "battel",
      {},
      headers
    );



    setBattleList((prevList) => [...prevList, ...response?.data?.data]);
    setTotalRecords(response?.data?.totalRecords);
  };

  const handleBattleCard = (item) => {
    dispatch(updateBattleId(item));
    navigate(`/battle/${item}`, {
      state: { itemData: item, battles: battleList },
    });
  };

  const getNewBattleOfGenre = async () => {
    const response = await cs.get(
      `getNewOrEndingBattleByGenre/${genre}/new?page=${page}`,
      "battel",
      {},
      headers
    );
    setBattleList((prevList) => [...prevList, ...response?.data?.data]);
    setTotalRecords(response?.data?.totalRecords);
  };

  const getEndingBattleOfGenre = async () => {
    const response = await cs.get(
      `getNewOrEndingBattleByGenre/${genre}/end?page=${page}`,
      "battel",
      {},
      headers
    );
    setBattleList((prevList) => [...prevList, ...response?.data?.data]);
    setTotalRecords(response?.data?.totalRecords);
  };

  const getAwaitingBattleOfGenre = async () => {
    const response = await cs.get(
      `awaitingBattlesOfGenre/${genre}?page=${page}`,
      "battel",
      null,
      headers
    );
    setBattleList((prevList) => [...prevList, ...response?.data?.data]);
    setTotalRecords(response?.data?.totalRecords);
  };

  const getBattleList = async () => {
    if (trackId) {
      const response = await cs.get("otherBattles/" + trackId, "battel", {}, headers);
      setBattleList(response?.data?.data);
      setTotalRecords(response?.data?.totalRecords);
    }

  };

  const getTrendingBattles = async () => {
    const response = await cs.get('trendingBattles', 'battel', null, headers)
    setTrendingBattle(response?.data?.data);
    setTotalRecords(response?.data?.totalRecords);
  }

  const getRecommendedBattles = async () => {
    const response = await cs.get('getRecommendedBattles', 'battel', null, headers)
    setBattleList(response?.data?.data);
    setTotalRecords(response?.data?.totalRecords);
  }
  console.log('battleList', battleList)
  console.log('battleList loading', loading)
  return (
    <>
      <Sidebar />
      <Header />
      <div className="viewall-container-wrap">
        <div className="main-wrapper">
          <div className="main-container">
            <div className="page-title">{title}</div>
            {type == "battle" && (
              <div className="battlecard-list-wrapper">
                {battleList && battleList?.length > 0 ? (
                  battleList.map((item) => (
                    <div
                      key={item?._id}
                      onClick={() => {
                        handleBattleCard(item?._id);
                      }}
                    >
                      <BattleCard
                        battleId={item?._id}
                        type={item?.battleOpponentStatus ? null : "awaiting"}
                      />
                    </div>
                  ))
                ) : (
                  <div className="loader-container">
                    <div className="loader">
                      <BallTriangle
                        type="TailSpin"
                        color="#00BFFF"
                        height={50}
                        width={50}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            {type == "artist" && (
              <div className="popular-artist-slider-wrap">
                {artist && artist?.length > 0 ? (
                  artist.map((item) => (
                    <SwiperSlide
                      key={item?.artistDetails?.[0]?._id}
                      onClick={() => {
                        navigate(`/profile/${item?.artistDetails?.[0]?._id}`, {
                          state: {
                            type: "user",
                            id: item?.artistDetails?.[0]?._id,
                          },
                        });
                      }}
                    >
                      <Artist artist={item?.artistDetails?.[0]} />
                    </SwiperSlide>
                  ))
                ) : (
                  <div className="loader-container">
                    <div className="loader">
                      <BallTriangle
                        type="TailSpin"
                        color="#00BFFF"
                        height={50}
                        width={50}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

            {type == "leaderboard" && (
              <div className="battle-lb-card-wrapper">
                {leaderboard && leaderboard?.length > 0 ? (
                  leaderboard.map((item) => (
                    <GenreCard
                      item={item}
                      key={item?.topWinners?.[0]?.genreId?._id}
                    />
                  ))
                ) : (
                  <div className="loader-container">
                    <div className="loader">
                      <BallTriangle
                        type="TailSpin"
                        color="#00BFFF"
                        height={50}
                        width={50}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

            {type == "trendingBattle" && (
              <div className="battlecard-list-wrapper">
                {trendingBattle && trendingBattle.length > 0 ? (
                  trendingBattle.map((item) => (
                    <div
                      key={item.battleData._id}
                      onClick={() => handleBattleCard(item.battleData._id)}
                    >
                      <BattleCard
                        battleId={item.battleData._id}
                        trending={trending}
                        type={
                          item.battleData.battleOpponentStatus
                            ? null
                            : "awaiting"
                        }
                      />
                    </div>
                  ))
                ) : (
                  <div className="loader-container">
                    <div className="loader">
                      <BallTriangle
                        type="TailSpin"
                        color="#00BFFF"
                        height={50}
                        width={50}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

            {type == "track" && (
              <div className="track-list-wrapper">
                {trackList && trackList?.length > 0 ? (
                  trackList.map((item) => (
                    <TrackPlayer
                      key={item?._id}
                      trackToBePlayed={item}
                      isPlayingSong={
                        track?._id === item?._id && isPlaying ? true : false
                      }
                      list={trackList}
                    />
                  ))
                ) : (
                  <div className="loader-container">
                    <div className="loader">
                      <BallTriangle
                        type="TailSpin"
                        color="#00BFFF"
                        height={50}
                        width={50}
                      />
                    </div>
                  </div>

                )}
              </div>
            )}
            <div ref={loader} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAll;
