import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from "react-helmet";

import Header from '../../Component/Header/Header'
import Sidebar from '../../Component/Sidebar/Sidebar'
import ActiveBattleBannerImg from '../../assets/images/active-battle-banner.png'
// import ImgPlaceholder from '../../assets/images/chat-placeholder.png'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
// import ActiveBattlePage from '../BattlePage/ActiveBattlePage/ActiveBattlePage'
import { Scrollbars } from 'react-custom-scrollbars-2';
import BattleCard from '../../Component/BattelCard/BattleCard'
import { Button, Form, InputGroup } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import UserImg from '../../assets/images/default.png'
// import BadgeImg from '../../assets/images/icons/badge.png'
import './UserProfile.scss'
import { BattleIcon, ChatIcon, CheckIcon, DollorIcon, InstagramIcon, SearchIcon, TikTokIcon, ViewAllIcon, XIcon, YouTubeIcon } from '../../Component/Icons'
import 'swiper/css';
import TrackPlayer from '../../Component/TrackPlayer/TrackPlayer'
import StartBattleModal from '../../Modal/StartBattleModal/StartBattleModal'
import OpenBattleModal from '../../Modal/OpenBattleModal/OpenBattleModal'
import * as cs from '../../Api/cs'
import useHeader from '../../Helper/useHeader'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
// import { bucketUrl } from '../../Helper/Constant'
import { updateBattleId } from '../../Redux/Slices/battle.slice'
import { updateShowChat, updateChatUserId } from '../../Redux/Slices/chat.slice'
// import Player from '../../Component/Player/Player'
import { BallTriangle } from 'react-loader-spinner';
import Select from "react-select";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import CrownIcon from '../../assets/images/icons/crown.svg'
import AddNewTrackModal from '../../Modal/AddNewTrackModal/AddNewTrackModal'
import FanModal from '../../Modal/FanModal/FanModal'
import FollowingModal from '../../Modal/FollowingModal/FollowingModal'
import { updateUserData } from '../../Redux/Slices/user.slice'
import { updateSearch } from '../../Redux/Slices/search.slice'
import Login from '../Login/Login'
import SignUp from '../SignUp/SignUp'
import OtpModal from '../../Modal/OtpModal'
import EmailLinkForPassword from '../EmailLinkForPassword/EmailLinkForPassword'
import SetUpAccount from '../../Modal/SetUpAccount/SetUpAccount'
import { updateactiveSideBarMenu } from '../../Redux/Slices/sidebar.slice'
import { updatePlayerFocus } from '../../Redux/Slices/player.slice'
import { SocketContext } from '../../Context/socket'
// import { faMobile } from '@fortawesome/free-solid-svg-icons'
import CustomTextModal from '../../Modal/CustomTextModal'

import { AnalyticsSendView } from '../../Helper/Analytics';


import { capitalizeFirstLetter, updateDocumentTitle, updateMetaTags } from '../../Utils';



const fitlerOptions = [
    { value: "1", label: "Newest First", },
    { value: "-1", label: "Oldest First" },
];
const UserProfile = () => {


    const [isSearchable, setIsSearchable] = useState(false);
    const headers = useHeader()
    const { id } = useParams()
    const dispatch = useDispatch()

    const [isArtist, setIsArtist] = useState(false);
    const location = useLocation();
    // const { type, id } = location.state;
    const [showModal, setShowModal] = useState(false)
    const [battleOption, setBattleOption] = useState('')
    const [trackInfo, setTrackInfo] = useState('');
    const [showOpenBattleModal, setShowOpenBattleModal] = useState(false);
    const [addToPlaylist, setAddToPlaylist] = useState(false);
    const [battleList, setBattleList] = useState([])
    const [sort, setSort] = useState('dsc')
    // const [filter, setFilter] = useState('abcd')
    const [trackList, setTrackList] = useState([])
    const [fanContent, setFanContent] = useState('')
    const { userId } = useSelector(state => state?.user)
    const [userData, setUserData] = useState({})
    const [followingData, setFollowingData] = useState({})
    const [time, setTime] = useState('')
    const [activeButton, setActiveButton] = useState('Active battles');
    // const [track, setTrack] = useState({})
    const [showPlayer, setShowPlayer] = useState(false)
    const [battleHistory, setBattleHistory] = useState({})
    const [awaitingBattle, setAwaitingBattle] = useState([])
    const [voteList, setVoteList] = useState([])
    const [activeHeading, setActiveHeading] = useState("Battles")
    const [myTrack, setMyTrack] = useState([])
    const [favoriteTrack, setFavoriteTrack] = useState([])
    const [searchData, setSearchData] = useState('')
    const [showAddTrack, setShowAddTrack] = useState(false)
    const [favoriteBattle, setFavoriteBattle] = useState([])
    const [fanModal, setFanModal] = useState(false)
    const [followingModal, setFollowingModal] = useState(false)
    const [opponent, setOpponent] = useState()
    const [filter, setFilter] = useState(fitlerOptions[0].value)
    const [userGenre, setUserGenre] = useState([])
    const [showLogin, setShowLogin] = useState(false);
    const [showOtpModal, setShowOtpModel] = useState(false)
    const [emailLinkModal, setEmailLinkModal] = useState(false)
    const [showSignUp, setShowSignUp] = useState(false)
    const [showSetUp, setShowSetUp] = useState(false)
    const [user, setUser] = useState(null)
    const [UserId, setUserId] = useState(null)
    const [token2, setToken2] = useState(null)
    const [loading, setLoading] = useState(false);
    const [userBadges, setUserBadges] = useState([])
    const [activeSection, setActiveSection] = useState('battles');
    const [showModalSendTips, setShowModalSendTips] = useState(false);

    const handleShowSendTips = () => setShowModalSendTips(true);
    const handleCloseSendTips = () => setShowModalSendTips(false);

    useEffect(() => {
    }, [activeSection]);

    const handleSectionChange = (section) => (e) => {
        e.preventDefault();
        if (activeSection !== section) {
            setActiveSection(section);
        }
    };

    const socket = useContext(SocketContext)

    const handleLoginClose = () => {
        setShowLogin(false)
    }
    const handleOtpClose = () => {
        setShowOtpModel(false)
    }
    const handleSignUpClose = () => {
        setShowSignUp(false)
    }
    const handleSetUpClose = () => {
        setShowSetUp(false)
    }
    const handleEmailLinkClose = () => {
        setEmailLinkModal(false)
    }

    const { track, isPlaying, playerFocus } = useSelector(state => state.player)

    const navigate = useNavigate()
    const { search } = useSelector(state => state.search)


    const handleFocus = () => {
        dispatch(updatePlayerFocus(false))
    };

    const handleBlur = () => {


        dispatch(updatePlayerFocus(true))
    };




    // useEffect(() => {
    //     if (search) {
    //         dispatch(updateSearch(''))
    //     }
    //     if (userId !== id) {
    //         setIsArtist(true)
    //         getTrackList()
    //     }
    //     else {
    //         setIsArtist(false)
    //     }
    //     getFollowingFollowers()
    //     getUserProfileById()
    //     getBattleList()
    //     getMyTrack()
    //     getUserGenreList()
    //     getLoggedInUserInfo()
    // }, [])

    // useEffect(() => {
    //     if (userId !== id) {
    //         setIsArtist(true)
    //         getTrackList()
    //     }
    //     else {
    //         setIsArtist(false)
    //     }
    //     getFollowingFollowers()
    //     getUserProfileById()
    //     getBattleList()
    //     getUserGenreList()
    // }, [id])

    useEffect(() => {
        // Function to fetch required data
        const fetchData = async () => {
            // Fetch necessary data based on conditions
            if (search) {
                dispatch(updateSearch(''));
            }
            getFollowingFollowers();
            // getLoggedInUserInfo();
            // getUserProfileById();

            console.log('getUserProfileById userId', userId)
            console.log('getUserProfileById id', id)
            if (id === userId) {
                getLoggedInUserInfo();
            }
            else {
                getUserProfileById();
            }
            getUserGenreList();
            scrollToTop();
        };

        // Call fetchData function
        fetchData();
    }, [id]);

    useEffect(() => {
        if (userId !== id) {
            setIsArtist(true);
            getTrackList();
            getBattleListOfArtist()
        } else {
            setIsArtist(false);
            getMyTrack();
            getBattleList();
        }
    }, [userId])

    /** Scroll To Top Function  */

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        scrollToTop()
    }, [location])

    useEffect(() => {
        GetbadgesbyId();

        return () => {
            updateDocumentTitle('');
            updateMetaTags([
                {
                    name: 'og:title',
                    value: ''
                },
                {
                    name: 'twitter:title',
                    value: ''
                }
            ])
        }
    }, [])


    useEffect(() => {
        getFollowingFollowers()
        if (userId === id) {
            getBattleList()
        }
        // getTrackList()
        getMyTrack()
    }, [showOpenBattleModal, showAddTrack])




    useEffect(() => {
        if (location?.state?.activeTab === "Active battles") {
            setActiveHeading('Battles')
            setActiveButton('Active battles')
            dispatch(updateactiveSideBarMenu('Active battles'))
        }
        else if (location?.state?.activeTab === 'Battle history') {
            setActiveHeading('Battles')
            setActiveButton('Battle history')
            dispatch(updateactiveSideBarMenu('Battle history'))
        }
        else if (location?.state?.activeTab === 'My Track') {
            setActiveHeading('Library')
            setActiveButton('My Track')
            dispatch(updateactiveSideBarMenu('My Track'))

        }
        else if (location?.state?.activeTab === 'Liked Tracks') {
            setActiveHeading('Library')
            setActiveButton('Favorite Track')
            dispatch(updateactiveSideBarMenu('Liked Tracks'))
        }
    }, [location])


    useEffect(() => {
        if (userId === id) {
            dispatch(updateactiveSideBarMenu(activeButton))
            if (activeButton === 'Active battles') {
                getBattleList()
            }
            else if (activeButton == 'Awaiting battles') {
                getAwaitingBattle()
            }
            else if (activeButton == 'Battle history') {
                getBattleHistory()
            }
            else if (activeButton == 'My Votes') {
                getVoteList()
            }
            else if (activeButton == 'Favorites') {
                getFavoriteBattle()
            }
            else if (activeButton == 'Favorite Track') {
                getFavoriteTrack()
            }
        }
    }, [filter, activeButton])

    useEffect(() => {
        if (searchData === null || searchData === undefined || searchData === "") {
            getMyTrack()
        }
    }, [searchData])


    // const getUserProfileById = () =>
    // {
    //     const response = await cs.get()
    // }

    const createBattle = () => {
        setShowModal(true)
    }

    const handleOpenBattleClose = () => setShowOpenBattleModal(false);
    const handleClose = () => setShowModal(false);

    const getBattleList = async () => {
        const response = await cs.get('status/' + 'active' + '/' + id + '/' + filter, 'battel', {}, null)
        // setActiveButton('Active battles');
        setBattleList(response?.data?.data)

    }

    const getBattleListOfArtist = async () => {
        const response = await cs.get('getAllBattlesOfArtist/' + id, 'battel', {}, headers)
        setBattleList(response?.data?.data)
    }


    const addFollower = async () => {
        if (userId == null) {
            setShowLogin(true)
        }
        else if (userId != null) {
            const _data = {
                "followUser": id
            }
            const response = await cs.post('add', 'follow', _data, headers)
            if (response?.data?.data) {
                const socket_data = {
                    fanId: userId,
                    userId: id,
                    status: response?.data?.data?.status
                }
                socket.emit("new_fan", socket_data)
            }

            getFollowingFollowers()
        }


    }




    const getFollowingFollowers = async () => {
        const response = await cs.get('getfollowers/' + id + "/" + userId, 'follow', {}, headers)


        if (response?.data?.code === 200) {
            setFollowingData(response?.data?.data)
            if (response?.data?.data?.fanStatus == 1) {
                setFanContent("Unfollow")
            }
            else {
                setFanContent("I'm a fan")
            }

        }

    }


    const getTrackList = async () => {
        const response = await cs.get('getById/' + id, 'track', {}, null)

        if (response?.data?.code === 200) {
            setTrackList(response?.data?.data)
            // dispatch(updateTrackList(response?.data?.data))
        }
    }


    const getUserProfileById = async () => {
        setLoading(true)
        const response = await cs.get('getUserList/' + id, 'users', {}, headers);
        if (response?.data?.code === 200) {
            const user = response?.data?.data;
            handleUpdatePageTitle(user)
            setUserData(user)
            const formattedTime = moment(response?.data?.data?.createdAt).format('MMMM YYYY');
            setTime(formattedTime);
            setLoading(false)
        }
    }

    const handleUpdatePageTitle = user => {
        if (user?.name) {
            const title = `${capitalizeFirstLetter(user.name)} Profile | CNTNDR`
            updateDocumentTitle(title);
        }
    }

    const getLoggedInUserInfo = async () => {
        setLoading(true)
        const response = await cs.get("getUserList/" + userId, 'users', {}, headers)
        const user = response?.data?.data;
        handleUpdatePageTitle(user)

        setUserData(response?.data?.data)
        const formattedTime = moment(response?.data?.data?.createdAt).format('MMMM YYYY');
        setTime(formattedTime);

        dispatch(updateUserData(response?.data?.data))
        setLoading(false)
    }


    const getBattleHistory = async () => {
        const response = await cs.get('winner2/' + userId + '/' + filter, 'battel', {}, headers)
        setActiveButton('Battle history')
        // setBattleList(response?.data?.data)
        setBattleHistory(response?.data?.winnerLoserList)
    };




    const handleButtonClick = (button, action) => {
        setActiveButton(button);
        if (action !== null) {

            action();
        }
    };



    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Your Pick
        </Tooltip>
    );

    const getAwaitingBattle = async () => {
        const response = await cs.get('awaitingOpp/' + userId + '/' + filter, "battel", {}, headers)
        console.log('responsess', response)
        setActiveButton('Awaiting battles')
        setAwaitingBattle(response?.data?.data)
    }

    const getVoteList = async () => {
        const response = await cs.get('/' + userId + '/' + filter, 'battleVote', {}, headers)
        setActiveButton('My Votes')
        setVoteList(response?.data)
    }

    const getMyTrack = async () => {
        // setActiveButton('My Track')
        const response = await cs.get('getById/' + userId, "track", {}, headers)
        setMyTrack(response?.data?.data)
        // dispatch(updateTrackList(response?.data?.data))
    }

    const handleActiveHeading = (heading) => {
        setActiveHeading(heading)
        if (heading === "Battles") {
            setActiveButton("Active battles")
        }

        else if (heading === "Library") {
            setActiveButton("My Track")
        }

    }

    const handleBattleCard = (item, battles = battleList) => {
        dispatch(updateBattleId(item))
        navigate(`/battle/${item}`, { state: { itemData: item, battles: battles } })
    }

    const getFavoriteTrack = async () => {
        setActiveButton("Favorite Track")
        const response = await cs.get("myFavList/" + userId + '/' + filter, "myFav", {}, headers)
        setFavoriteTrack(response?.data?.data)
        // dispatch(updateTrackList(response?.data?.data))

    }

    const trackSearch = async () => {


        const response = await cs.get('trackSearch/' + userId + "/" + searchData, 'track', {}, headers)
        setMyTrack(response?.data?.data)


    }




    const handleAddTrack = () => {
        setShowAddTrack(true)
    }

    const handleAddTrackClose = () => {
        setShowAddTrack(false)
    }

    const getFavoriteBattle = async () => {
        setActiveButton("Favorites")
        const response = await cs.get("list/" + userId + '/' + filter, "battleMyFav", {}, headers)
        setFavoriteBattle(response?.data?.data)
    }


    const handleFilterChange = (selectedOption) => {

        setFilter(selectedOption.value);
    };

    const getUserGenreList = async () => {
        const response = await cs.get('getUserGenre/' + id, 'genre', {}, null)

        setUserGenre(response?.data?.data)
    }


    const handleMessageChat = (user_id) => {

        if (userId !== null) {
            dispatch(updateShowChat(true))
            dispatch(updateChatUserId(user_id))
        }
        else if (userId == null) {
            setShowLogin(true)
        }
    }

    const handleChallenge = async () => {
        if (userId != null) {
            setShowModal(true)
            setOpponent(id)
        }
        else if (userId == null) {
            setShowLogin(true)
        }

    }

    const GetbadgesbyId = async () => {
        try {
            const response = await cs.get('getEarnedBadges/' + id, 'badge', {}, null);
            setUserBadges(response?.data?.data);
        } catch (error) {
            console.error('Error fetching badges:', error);
        }
    };


    const handleClick = () => {
        addFollower();
    };

    const getBadgeImage = (badge) => {
        const currentLevel = badge?.level; // Assuming 'level' represents user's current level
        const levels = badge?.badgeId?.levels || []; // Default to an empty array if levels is undefined
        const levelData = levels.find(level => level.levelNumber === currentLevel);

        return levelData ? levelData.levelImage : badge.badgeId.badgeImage;
    };
    return (
        <>
            <Header />
            <Sidebar />
            {!loading ? <div className='profile-page-wrapper'>
                <div className='profile-page-banner-wrapper'>
                    <img src={userData?.banner_image ? userData?.banner_image : ActiveBattleBannerImg} />
                </div>
                <div className="profile-page-container">
                    <div className='main-wrapper'>
                        <div className='profile-wrapper'>
                            <div className='profile-left'>

                                <div className="profile_img">
                                    <img src={userData?.image ? userData?.image : UserImg} alt='' />
                                </div>
                                <div className="username-wrap">
                                    <h1>{userData?.name}</h1>
                                    <div className='profile-user-name'>
                                        @{userData?.userName}
                                    </div>
                                </div>




                                <div className="social_track">
                                    <div className="social_item cursor-pointer" onClick={() => {
                                        if (followingModal) {
                                            setFollowingModal(false)
                                        }
                                        setFanModal(!fanModal)
                                    }} >
                                        <label>Followers</label>
                                        <div className="counter">{followingData?.fan}</div>
                                    </div>
                                    <div className="social_item cursor-pointer" onClick={() => {
                                        if (fanModal) {
                                            setFanModal(false)
                                        }
                                        setFollowingModal(!followingModal)
                                    }}>
                                        <label>Following</label>
                                        <div className="counter">{followingData?.following}</div>
                                    </div>
                                    <div className="social_item">
                                        <label>Tracks</label>
                                        <div className="counter">{followingData?.trackCount}</div>
                                    </div>
                                </div>

                                {/* {
                                    isArtist && <>
                                        <div className="following-main-wrap">
                                            <div className="social_btn">
                                                <div className='btn-wrap'>
                                                    <Button variant='primary' onClick={addFollower}>{userId === null ? "Follow" : fanContent}</Button>
                                                </div>
                                                <div className='btn-wrap'>
                                                    <Button variant='secondary' onClick={() => handleMessageChat(userData?._id)}><ChatIcon /> Message</Button>
                                                </div>
                                            </div>
                                            <div className="send_tips cursor-pointer">
                                                <div className="tips"><i><DollorIcon /></i>Send Tips</div>
                                                <div className="tips " onClick={handleChallenge}><i><BattleIcon /></i>Challenge</div>
                                            </div>
                                        </div>
                                    </>
                                } */}
                                {
                                    <div className="sidebar_item tentacion">

                                        <h1>{userData?.name}</h1>
                                        <div className='profile-user-name'>
                                            @{userData?.userName}
                                        </div>
                                    </div>

                                }
                                {
                                    isArtist && <>
                                        <div className="sidebar_item social_btn">
                                            <div className='btn-wrap'>
                                                {/* <Button variant='primary' onClick={addFollower}>{userId === null ? "Follow" : fanContent}</Button> */}
                                                {fanContent === "Unfollow" ? (
                                                    <Button
                                                        variant='outline-primary'
                                                        onClick={handleClick}
                                                    >
                                                        {userId === null ? "Follow" : fanContent}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        variant='primary'
                                                        onClick={handleClick}
                                                    >
                                                        {userId === null ? "Follow" : fanContent}
                                                    </Button>
                                                )}
                                            </div>
                                            <div className='btn-wrap'>
                                                <Button variant='secondary' onClick={() => handleMessageChat(userData?._id)}><ChatIcon /> Message</Button>
                                            </div>
                                        </div>
                                        <div className="sidebar_item send_tips">
                                            {/* <div className="tips cursor-pointer" data-toggle="tooltip" data-placement="top" title="Coming Soon...">
                                                <i><DollorIcon /></i>Send Tips
                                            </div> */}
                                            <div className="tips cursor-pointer" onClick={handleShowSendTips}>
                                                <i><DollorIcon /></i> Send Tips
                                            </div>
                                            <div className="tips cursor-pointer" onClick={handleChallenge}><i><BattleIcon /></i>Challenge</div>
                                        </div>

                                    </>
                                }

                                <div className="battle_won">
                                    <h4><img src={CrownIcon} alt='Crown' /> Battles won: </h4>
                                    <div className="counter">{userData?.battlesWonCount || 0}</div>
                                </div>
                                <div className="sidebar_item tentacion">
                                    {/* <h1>{userData?.name}</h1>
                                    <div className='profile-user-name'>
                                        @{userData?.userName}
                                    </div> */}
                                    <p className="desc">{(userData?.bio)}</p>
                                    <span className="date">CNTNDR since  {time}</span>
                                </div>







                                {/* Only For Mobile start  */}
                                {isArtist ?
                                    <div className='mobile-profile-content-wrap'>
                                        <div className='profile-btn-wrap'>
                                            <Button
                                                className={activeSection === 'battles' ? 'active' : ''}
                                                variant='primary'
                                                onClick={handleSectionChange('battles')}
                                            >
                                                Battles
                                            </Button>
                                            <Button
                                                className={activeSection === 'tracks' ? 'active' : ''}
                                                variant='primary'
                                                onClick={handleSectionChange('tracks')}
                                            >
                                                Tracks
                                            </Button>
                                            <Button
                                                className={activeSection === 'about' ? 'active' : ''}
                                                variant='primary'
                                                onClick={handleSectionChange('about')}
                                            >
                                                About
                                            </Button>
                                        </div>

                                        <div className='mobile-profile-content'>
                                            {activeSection === 'about' && (
                                                <div className='about-content-wrap-mobile'>
                                                    <div className="battle_won_for_mobile">
                                                        <h4><img src={CrownIcon} alt='Crown' /> Battles won: </h4>
                                                        <div className="counter">{userData?.battlesWonCount || 0}</div>
                                                    </div>
                                                    <div className="sidebar_item_tentacion_for_mobile">
                                                        <p className="desc">{userData?.bio}</p>
                                                    </div>
                                                    {/* genres  */}
                                                    {
                                                        userGenre && userGenre.length > 0 && (
                                                            <div className="sidebar_item genres">
                                                                <h1>Genres</h1>
                                                                <div className="tag_wrapper">
                                                                    {
                                                                        userGenre.map((item) => (
                                                                            <div key={item?._id}>
                                                                                <Button variant='' onClick={() => navigate(`/genre/${item?._id}`, { state: { genre: item?._id } })}>
                                                                                    {item?.label}
                                                                                </Button>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {/* badges  */}
                                                    {userBadges?.length > 0 && (
                                                        <div className="sidebar_item badges">
                                                            <h1>Badges</h1>
                                                            <div className="badge_wrapper">
                                                                {userBadges.map(badge => (
                                                                    <div key={badge._id} className="badge_item">
                                                                        <img src={getBadgeImage(badge)} alt={badge?.badgeId?.badgeName || 'Badge'} />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {/* social media  */}
                                                    {(userData?.instagram || userData?.twitter || userData?.youtube || userData?.tiktok) &&
                                                        <div className="sidebar_item social_block">
                                                            <h1>Social</h1>
                                                            <div className="social_wrapper">
                                                                {userData?.instagram &&
                                                                    <a href={userData?.instagram} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                                                        <div className="social_item" style={{ color: "white" }}>
                                                                            <InstagramIcon />
                                                                        </div>
                                                                    </a>
                                                                }
                                                                {userData?.twitter &&
                                                                    <a href={userData?.twitter} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                                                        <div className="social_item" style={{ color: "white" }}>
                                                                            <XIcon />
                                                                        </div>
                                                                    </a>
                                                                }
                                                                {userData?.youtube &&
                                                                    <a href={userData?.youtube} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                                                        <div className="social_item" style={{ color: "white" }}>
                                                                            <YouTubeIcon />
                                                                        </div>
                                                                    </a>
                                                                }
                                                                {userData?.tiktok &&
                                                                    <a href={userData?.tiktok} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                                                        <div className="social_item" style={{ color: "white" }}>
                                                                            <TikTokIcon />
                                                                        </div>
                                                                    </a>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                    <span className="date">CNTNDR since  {time}</span>
                                                </div>
                                            )}

                                            {activeSection === 'tracks' && (
                                                <div className='artist-track-wrapper'>
                                                    {trackList && trackList?.length > 0 ? (
                                                        trackList.map((item) => (
                                                            <div className='artist-track-list-wrap' key={item?._id}>
                                                                <TrackPlayer
                                                                    trackToBePlayed={item}
                                                                    isPlayingSong={track?._id === item?._id && isPlaying}
                                                                    list={trackList}
                                                                />
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div style={{ color: "white" }}>Please Add Tracks</div>
                                                    )}

                                                </div>
                                            )}

                                            {activeSection === 'battles' && (
                                                <div className='artist-battle-slider-wrap'>
                                                    <div className='artist-battle-slider-title-wrap'>
                                                        {battleList && battleList.length > 0 ? (
                                                            <>

                                                                <div className='btn-wrap' onClick={() => {
                                                                    navigate(`/artist/${id ? id : 'all'}/battle`, {
                                                                        state: {
                                                                            type: "battle",
                                                                            title: "Battles",
                                                                            artistId: id
                                                                        }
                                                                    });
                                                                }}>
                                                                    {/* <Button >View All</Button> */}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <div style={{ color: "white" }}>Please Create A Battle</div>
                                                        )}
                                                    </div>
                                                    <div className='artist-battle-slider'>
                                                        <Swiper
                                                            spaceBetween={20}
                                                            breakpoints={{
                                                                1400: {
                                                                    slidesPerView: 2.4
                                                                },
                                                                1250: {
                                                                    slidesPerView: 1.4
                                                                },
                                                                991: {
                                                                    slidesPerView: 1.2
                                                                },
                                                                767: {
                                                                    slidesPerView: 2.2
                                                                },
                                                                300: {
                                                                    enabled: false
                                                                }
                                                            }}
                                                        >
                                                            {battleList && battleList.length > 0 &&
                                                                battleList.map((item) => (
                                                                    <SwiperSlide key={item?._id} onClick={() => handleBattleCard(item?._id)}>
                                                                        <BattleCard battleId={item?._id} type={item?.battleOpponentStatus ? null : 'awaiting'} />
                                                                    </SwiperSlide>
                                                                ))
                                                            }
                                                        </Swiper>
                                                        {battleList && battleList.length > 0 && (
                                                            <div className='viewall-btn-wrap'>
                                                                <Button variant='secondary' onClick={() => {
                                                                    navigate(`/artist/${id ? id : 'all'}/battle`, {
                                                                        state: {
                                                                            type: "battle",
                                                                            title: "Battles",
                                                                            artistId: id
                                                                        }
                                                                    });
                                                                }}>
                                                                    <ViewAllIcon /> View all
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}


                                        </div>
                                    </div> : null}



                                {
                                    userGenre && userGenre.length > 0 && (
                                        <div className="sidebar_item genres">
                                            <h1>Genres</h1>
                                            <div className="tag_wrapper">
                                                {
                                                    userGenre.map((item) => (
                                                        <div key={item?._id}>
                                                            <Button variant='' onClick={() => navigate(`/genre/${item?._id}`, { state: { genre: item?._id } })}>
                                                                {item?.label}
                                                            </Button>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    )
                                }

                                {userBadges?.length > 0 && (
                                    <div className="sidebar_item badges">
                                        <h1>Badges</h1>
                                        <div className="badge_wrapper">
                                            {userBadges.map(badge => (
                                                <div key={badge._id} className="badge_item">
                                                    <img src={getBadgeImage(badge)} alt={badge?.badgeId?.badgeName || 'Badge'} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {(userData?.instagram || userData?.twitter || userData?.youtube || userData?.tiktok) &&
                                    <div className="sidebar_item social_block">
                                        <h1>Social</h1>
                                        <div className="social_wrapper">
                                            {userData?.instagram &&
                                                <a href={userData?.instagram} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                                    <div className="social_item" style={{ color: "white" }}>
                                                        <InstagramIcon />
                                                    </div>
                                                </a>
                                            }
                                            {userData?.twitter &&
                                                <a href={userData?.twitter} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                                    <div className="social_item" style={{ color: "white" }}>
                                                        <XIcon />
                                                    </div>
                                                </a>
                                            }
                                            {userData?.youtube &&
                                                <a href={userData?.youtube} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                                    <div className="social_item" style={{ color: "white" }}>
                                                        <YouTubeIcon />
                                                    </div>
                                                </a>
                                            }
                                            {userData?.tiktok &&
                                                <a href={userData?.tiktok} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                                    <div className="social_item" style={{ color: "white" }}>
                                                        <TikTokIcon />
                                                    </div>
                                                </a>
                                            }
                                        </div>
                                    </div>
                                }

                            </div>

                            <div className={`profile-right ${isArtist ? 'artist' : ''}`}>
                                {!isArtist ?
                                    <>
                                        <div className='artist-main-wrap'>
                                            <div className='artist-battle-title-wrap'>
                                                <div className='update-profile '><Button type="button" variant='primary' onClick={() => { navigate('/private/edit-profile') }}>Edit Profile</Button></div>

                                                <div className='artist-battle-title'>
                                                    <span className={activeHeading === "Battles" ? 'active' : ''} onClick={() => { handleActiveHeading("Battles") }}> Battles</span>
                                                    <span className={activeHeading === "Library" ? 'active' : ''} onClick={() => { handleActiveHeading("Library") }}>Library</span>
                                                </div>
                                                <div className='artist-battle-nav-wrap'>
                                                    {activeHeading === "Battles" &&
                                                        <>

                                                            <div className='artist-battle-nav-left'>
                                                                <Button
                                                                    variant=''
                                                                    className={(activeButton === 'Active battles') ? 'active' : ''}
                                                                    onClick={() => handleButtonClick('Active battles', getBattleList)}
                                                                >
                                                                    Active battles
                                                                </Button>
                                                                <Button
                                                                    variant=''
                                                                    className={activeButton === 'Awaiting battles' ? 'active' : ''}
                                                                    onClick={() => handleButtonClick('Awaiting battles', getAwaitingBattle)}

                                                                >
                                                                    Awaiting Opponent
                                                                </Button>

                                                                <Button
                                                                    variant=''
                                                                    className={(activeButton === 'Battle history') ? 'active' : ''}
                                                                    onClick={() => handleButtonClick('Battle history', getBattleHistory)}
                                                                >
                                                                    Battle history
                                                                </Button>
                                                                <Button
                                                                    variant=''
                                                                    className={activeButton === 'My Votes' ? 'active' : ''}
                                                                    onClick={() => handleButtonClick('My Votes', getVoteList)}
                                                                >
                                                                    My Votes
                                                                </Button>
                                                                <Button
                                                                    variant=''
                                                                    className={activeButton === 'Favorites' ? 'active' : ''}
                                                                    onClick={() => handleButtonClick('Favorites', getFavoriteBattle)}
                                                                >
                                                                    Likes
                                                                </Button>
                                                            </div>

                                                            <div className='artist-battle-nav-right cursor-pointer'>
                                                                <div className='filter-wrap cursor-pointer'>
                                                                    <Select
                                                                        className="filter-select cursor-pointer"
                                                                        classNamePrefix="filter-select"
                                                                        isSearchable={isSearchable}
                                                                        defaultValue={fitlerOptions[0]}
                                                                        value={fitlerOptions.find(option => option.value === filter)} // Setting selected value
                                                                        onChange={handleFilterChange}
                                                                        options={fitlerOptions}
                                                                    />
                                                                </div>


                                                                <div className='btn-wrap'><Button variant='primary' onClick={createBattle}>Create Battle</Button> <Button variant='secondary' onClick={() => { navigate('/private/edit-profile') }}>Edit Profile</Button></div>

                                                            </div>
                                                        </>
                                                    }

                                                    {activeHeading === "Library" &&
                                                        <>
                                                            <div className='artist-battle-nav-left center'>
                                                                <Button
                                                                    variant=''
                                                                    className={activeButton === 'My Track' ? 'active' : ''}
                                                                    onClick={() => handleButtonClick('My Track', getMyTrack)}
                                                                >
                                                                    My Tracks
                                                                </Button>
                                                                <Button
                                                                    variant=''
                                                                    className={activeButton === 'Favorite Track' ? 'active' : ''}
                                                                    onClick={() => handleButtonClick('Favorite Track', getFavoriteTrack)}
                                                                >
                                                                    Likes
                                                                </Button>


                                                            </div>
                                                            <div className='artist-battle-nav-right'>
                                                                {activeHeading === "Library" && activeButton === "My Track" &&
                                                                    <>
                                                                        <div className='search-wrap'>


                                                                            <InputGroup>
                                                                                <Form.Control
                                                                                    placeholder="Search..."
                                                                                    name="search"
                                                                                    value={searchData} onChange={(e) => { setSearchData(e.target.value) }}
                                                                                    onFocus={handleFocus}
                                                                                    onBlur={handleBlur}
                                                                                />
                                                                                <InputGroup.Text  >
                                                                                    <div className='icon-wrap' onClick={trackSearch}><SearchIcon /></div>
                                                                                </InputGroup.Text>
                                                                            </InputGroup>



                                                                        </div>
                                                                    </>
                                                                }

                                                                {activeHeading === "Library" && activeButton === "Favorite Track" &&
                                                                    <div className='filter-wrap'>
                                                                        <Select
                                                                            className="filter-select"
                                                                            classNamePrefix="filter-select"
                                                                            isSearchable={isSearchable}
                                                                            defaultValue={fitlerOptions[0]}
                                                                            value={fitlerOptions.find(option => option.value === filter)} // Setting selected value
                                                                            onChange={handleFilterChange}
                                                                            options={fitlerOptions}
                                                                        />
                                                                    </div>
                                                                }
                                                                <div className='btn-wrap'><Button variant='primary' onClick={handleAddTrack} >Add a Track</Button> <Button variant='secondary' onClick={() => { navigate('/private/edit-profile') }} >Edit Profile</Button></div>
                                                            </div>
                                                        </>
                                                    }

                                                </div>
                                            </div>

                                            {/* For Battle History */}

                                            {activeHeading === "Battles" && activeButton === 'Battle history' && battleHistory && battleHistory?.length > 0 &&
                                                <div className='battle-history-wrapper'>

                                                    {battleHistory?.map((item) =>
                                                    (

                                                        <div className='battle-history-row' key={item?._id} onClick={() => { handleBattleCard(item?._id, battleHistory) }} >

                                                            <div className='battle-history-date'>{moment(item?.battleDetails?.battleEndDate).isBefore(moment()) ?
                                                                moment(item?.battleDetails?.battleEndDate).format('MMMM DD, YYYY') :
                                                                moment(item?.battleDetails?.battleEndDate).diff(moment(), 'days') >= 0 ?
                                                                    moment(item?.battleDetails?.battleEndDate).diff(moment(), 'days') + ' days, ' +
                                                                    moment(item?.battleDetails?.battleEndDate).diff(moment(), 'hours') % 24 + ' hours, ' +
                                                                    moment(item?.battleDetails?.battleEndDate).diff(moment(), 'minutes') % 60 + ' minutes' :
                                                                    moment(item?.battleDetails?.battleEndDate).format('MMMM DD, YYYY')
                                                            } </div>
                                                            <div className='battle-history-opponent-wrap'>
                                                                <div className='battle-vs-wrap'>VS</div>
                                                                <div className='battle-history-opponent'>
                                                                    <div className='battle-history-opponent-img'>
                                                                        <img src={item?.opponantDetails?.image ? item?.opponantDetails?.image : UserImg} />
                                                                        {/* <OverlayTrigger
                                                                            placement="top"
                                                                            delay={{ show: 250, hide: 400 }}
                                                                            overlay={renderTooltip}
                                                                        >
                                                                            <div className='icon-wrap'>
                                                                                <CheckIcon />
                                                                            </div>
                                                                        </OverlayTrigger> */}
                                                                    </div>
                                                                    <div className='battle-history-opponent-name'>{item?.opponantDetails?.name ? item?.opponantDetails?.name : item?.opponantDetails?.userName}</div>
                                                                </div>
                                                            </div>
                                                            <div className='battle-history-track-name'>{item?.userDetails?.trackDetails?.track_name}</div>

                                                            <div className='battle-history-result'>
                                                                {moment(item?.battleDetails?.battleEndDate).isBefore(moment()) &&
                                                                    <>
                                                                        {item?.battleDetails?.isTie === false ? item?.battleDetails?.winnerId === userId ? <div className='result-tag win'><span>👑</span> Win</div>
                                                                            : <div className='result-tag loss'>Loss</div>
                                                                            : <div className='result-tag loss'>Tie</div>

                                                                        }
                                                                    </>
                                                                }


                                                            </div>
                                                        </div>

                                                    ))
                                                    }
                                                </div>
                                            }

                                            {/* For My Votes  */}
                                            {activeHeading === "Battles" && activeButton === 'My Votes' &&
                                                voteList && voteList?.length > 0 &&
                                                <div className='my-votes-wrapper'>
                                                    {voteList.map((item, index) => (
                                                        <div className='my-votes-row' key={item?.battle?._id} onClick={() => { handleBattleCard(item?.battle?._id) }}>


                                                            <div className='my-votes-date'>
                                                                {moment(item?.battle?.battleEndDate).isBefore(moment()) ?
                                                                    moment(item?.battle?.battleEndDate).format('MMMM DD, YYYY') :
                                                                    moment(item?.battle?.battleEndDate).diff(moment(), 'days') >= 0 ?
                                                                        moment(item?.battle?.battleEndDate).diff(moment(), 'days') + ' days, ' +
                                                                        moment(item?.battle?.battleEndDate).diff(moment(), 'hours') % 24 + ' hours, ' +
                                                                        moment(item?.battle?.battleEndDate).diff(moment(), 'minutes') % 60 + ' minutes' :
                                                                        moment(item?.battle?.battleEndDate).format('MMMM DD, YYYY')
                                                                }
                                                            </div>


                                                            <div className='my-votes-opponents-wrap'>
                                                                <div className='my-votes-opponents'>
                                                                    <div className='my-votes-img'>
                                                                        <img src={item?.BattleCreatdBy?.[0]?.image ? item?.BattleCreatdBy?.[0]?.image : UserImg} />

                                                                        {item?.voteFor?.[0]?._id === item?.BattleCreatdBy?.[0]?._id &&
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                delay={{ show: 250, hide: 400 }}
                                                                                overlay={renderTooltip}
                                                                            >
                                                                                <div className='icon-wrap'>
                                                                                    <CheckIcon />
                                                                                </div>
                                                                            </OverlayTrigger>
                                                                        }
                                                                    </div>
                                                                    <div className='my-votes-opponent-name'>{item?.BattleCreatdBy?.[0]?.name ? item?.BattleCreatdBy?.[0]?.name : item?.BattleCreatdBy?.[0]?.userName}</div>
                                                                    {item?.Winner?.[0] && item?.Winner?.[0]?._id === item?.BattleCreatdBy?.[0]?._id &&
                                                                        <div className='winner-icon'><img src={CrownIcon} alt='Crown' /></div>

                                                                    }
                                                                </div>
                                                                <div className='my-votes-vs'>VS</div>
                                                                <div className='my-votes-opponents'>
                                                                    <div className='my-votes-img'> <img src={item?.BattleOpponentCreatdBy?.[0]?.image ? item?.BattleOpponentCreatdBy?.[0]?.image : UserImg} />

                                                                        {item?.voteFor?.[0]?._id === item?.BattleOpponentCreatdBy?.[0]?._id &&
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                delay={{ show: 250, hide: 400 }}
                                                                                overlay={renderTooltip}
                                                                            >
                                                                                <div className='icon-wrap'>
                                                                                    <CheckIcon />
                                                                                </div>
                                                                            </OverlayTrigger>
                                                                        }
                                                                    </div>
                                                                    <div className='my-votes-opponent-name'>{item?.BattleOpponentCreatdBy?.[0]?.name ? item?.BattleOpponentCreatdBy?.[0]?.name : item?.BattleOpponentCreatdBy?.[0]?.userName}</div>
                                                                    {item?.Winner?.[0] && item?.Winner?.[0]?._id === item?.BattleOpponentCreatdBy?.[0]?._id &&
                                                                        <div className='winner-icon'><img src={CrownIcon} alt='Crown' /></div>

                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            }

                                            {activeHeading === "Battles" && activeButton === 'Active battles' && <Scrollbars style={{ width: '100%', height: '600px' }}>
                                                <div className='artist-battle-wrap'>

                                                    {battleList && battleList?.length > 0 && battleList?.map((item) => (
                                                        <div key={item?._id} className="battleCardContainer" onClick={() => { handleBattleCard(item?._id, battleList) }}>
                                                            <BattleCard battleId={item?._id} type={item?.battleOpponentStatus ? null : 'awaiting'} />
                                                        </div>
                                                    ))}

                                                </div>
                                            </Scrollbars>
                                            }


                                            {activeHeading === "Battles" && activeButton === 'Awaiting battles' && awaitingBattle &&
                                                <div className='artist-battle-wrap'>
                                                    {awaitingBattle?.length > 0 && awaitingBattle?.map((item) => (
                                                        <div key={item?._id} className="battleCardContainer" onClick={() => { handleBattleCard(item?._id) }}>
                                                            <BattleCard battleId={item?._id} type={item?.battleOpponentStatus ? null : 'awaiting'} />
                                                        </div>
                                                    ))}
                                                </div>

                                            }

                                            {activeHeading === "Battles" && activeButton === 'Favorites' && <Scrollbars style={{ width: '100%', height: '600px' }}>
                                                <div className='artist-battle-wrap'>

                                                    {favoriteBattle && favoriteBattle?.length > 0 && favoriteBattle?.map((item) => (
                                                        <div className="battleCardContainer" key={item?.BattleId?.[0]?._id} onClick={() => { handleBattleCard(item?.BattleId?.[0]?._id) }}>
                                                            <BattleCard battleId={item?.BattleId?.[0]?._id} type={item?.BattleId?.[0]?.battleOpponentStatus ? null : 'awaiting'} />
                                                        </div>
                                                    ))}

                                                </div>
                                            </Scrollbars>
                                            }

                                            {activeHeading === "Library" && activeButton === 'My Track' && myTrack &&
                                                <div class="track-list-wrapper">


                                                    {myTrack?.length > 0 ? myTrack?.map((item) => {
                                                        return (
                                                            <div key={item?._id}>

                                                                <TrackPlayer key={item?._id} trackToBePlayed={item} isPlayingSong={track?._id === item?._id && isPlaying ? true : false} action={getMyTrack} list={myTrack} />

                                                            </div>

                                                        );
                                                    }) : <div className='no-data-wrap'>No Data Available</div>}


                                                </div>
                                            }

                                            {activeHeading === "Library" && activeButton === 'Favorite Track' && favoriteTrack &&
                                                <div class="track-list-wrapper">


                                                    {favoriteTrack?.length > 0 ? favoriteTrack?.map((item) => {
                                                        return (
                                                            <div key={item?.trackId?.[0]?._id}>
                                                                <TrackPlayer trackToBePlayed={item?.trackId?.[0]} isPlayingSong={track?._id === item?.trackId?.[0]?._id && isPlaying ? true : false} action={getFavoriteTrack} list={myTrack} />
                                                            </div>

                                                        );
                                                    }) : <div className='no-data-wrap'>No Data Available</div>}


                                                </div>
                                            }
                                        </div>
                                    </> :

                                    <>
                                        <div className='artist-track-main-wrap'>
                                            <div className='artist-battle-slider-wrap'>
                                                <div className='artist-battle-slider-title-wrap'>
                                                    {battleList && battleList?.length > 0 &&
                                                        <div className='artist-battle-slider-title'>Battles</div>
                                                    }
                                                    {battleList && battleList?.length > 0 && <div className='btn-wrap' onClick={() => {
                                                        navigate(`/artist/${id ? id : 'all'}/battle`, {
                                                            state: {
                                                                type: "battle",
                                                                title: "Battles",
                                                                artistId: id

                                                            }
                                                        });
                                                    }}><Button variant='light' >View All</Button></div>
                                                    }
                                                </div>
                                                <div className='artist-battle-slider'>
                                                    <Swiper
                                                        spaceBetween={20}
                                                        breakpoints={{

                                                            1400: {
                                                                slidesPerView: 2.4
                                                            },
                                                            1250: {
                                                                slidesPerView: 1.4
                                                            },
                                                            991: {
                                                                slidesPerView: 1.2,

                                                            },
                                                            767: {
                                                                slidesPerView: 2.2,

                                                            },
                                                            300: {
                                                                enabled: false
                                                            }
                                                        }}

                                                    >

                                                        {battleList && battleList?.length > 0 &&
                                                            battleList.map((item) => (
                                                                <SwiperSlide key={item?._id} onClick={() => { handleBattleCard(item?._id) }}>
                                                                    <BattleCard battleId={item?._id} type={item?.battleOpponentStatus ? null : 'awaiting'} />
                                                                </SwiperSlide>
                                                            ))
                                                            // :
                                                            // <div style={{ color: "white" }}>No battles available</div>
                                                        }

                                                    </Swiper>
                                                    {battleList && battleList?.length > 0 && <div className='viewall-btn-wrap' ><Button variant='secondary' onClick={() => {
                                                        navigate(`/artist/${id ? id : 'all'}/battle`, {
                                                            state: {
                                                                type: "battle",
                                                                title: "Battles",
                                                                artistId: id

                                                            }
                                                        });
                                                    }}><ViewAllIcon /> View all</Button></div>
                                                    }
                                                </div>
                                            </div>
                                            <div className='artist-track-wrapper'>
                                                <div className='artist-track-title'>Tracks</div>
                                                <div className='artist-track-list-wrap' >
                                                    {
                                                        trackList && trackList?.length > 0 ?
                                                            trackList?.map((item) => (
                                                                <div key={item?._id}>
                                                                    <TrackPlayer trackToBePlayed={item} isPlayingSong={(track?._id === item?._id && isPlaying) ? true : false} list={trackList} />
                                                                </div>
                                                            )) :
                                                            <div style={{ color: "white" }}>No tracks available</div>
                                                    }
                                                </div>
                                            </div>
                                            {/* {showPlayer && <Player artistId={id} track={track} setTrack={setTrack} />} */}
                                        </div></>

                                }

                            </div>
                        </div>
                    </div>
                </div>


                {showModal && <StartBattleModal showModal={showModal} handleClose={handleClose} setShowModal={setShowModal} setShowOpenBattleModal={setShowOpenBattleModal} setTrackInfo={setTrackInfo} setBattleOption={setBattleOption} setAddToPlaylist={setAddToPlaylist} addToPlaylist={addToPlaylist} />}
                {showOpenBattleModal && <OpenBattleModal showOpenBattleModal={showOpenBattleModal} trackInfo={trackInfo} setShowModal={setShowModal} setShowOpenBattleModal={setShowOpenBattleModal} battleOption={battleOption} getBattleList={getBattleList} handleOpenBattleClose={handleOpenBattleClose} addToPlaylist={addToPlaylist} opponent={opponent} />}
                {showAddTrack && <AddNewTrackModal showAddTrack={showAddTrack} handleAddTrackClose={handleAddTrackClose} getMyTrack={getMyTrack} />}
                {fanModal && <FanModal fanModal={fanModal} setFanModal={setFanModal} artistId={id} getFollowingFollowers={getFollowingFollowers} />}
                {followingModal && <FollowingModal followingModal={followingModal} setFollowingModal={setFollowingModal} artistId={id} getFollowingFollowers={getFollowingFollowers} />}
                {showLogin && <Login showLogin={showLogin} handleLoginClose={handleLoginClose} setShowLogin={setShowLogin} setShowModel={setShowOtpModel} setEmailLinkModal={setEmailLinkModal} setShowSignUp={setShowSignUp} setUser={setUser} setShowSetUp={setShowSetUp} setId={setUserId} setToken2={setToken2} />}
                {showSignUp && <SignUp showSignUp={showSignUp} handleSignUpClose={handleSignUpClose} setShowSignUp={setShowSignUp} setShowLogin={setShowLogin} setShowModel={setShowOtpModel} setUser={setUser} user={user} setId={setUserId} setToken2={setToken2} />}
                {showOtpModal && <OtpModal showModal={showOtpModal} handleClose={handleOtpClose} setShowModel={setShowOtpModel} user={user} setShowLogin={setShowLogin} setShowSetUp={setShowSetUp} id={UserId} token2={token2} />}
                {emailLinkModal && <EmailLinkForPassword emailLinkModal={emailLinkModal} handleEmailLinkClose={handleEmailLinkClose} setShowLogin={setShowLogin} />}
                {showSetUp && <SetUpAccount showSetUp={showSetUp} handleSetUpClose={handleSetUpClose} />}
                {<CustomTextModal showModal={showModalSendTips} handleClose={handleCloseSendTips} title="Send Tips" body="Coming soon!" />}

            </div >
                :
                <div className="loader-container">
                    <div className="loader">
                        <BallTriangle
                            type="TailSpin"
                            color="#00BFFF"
                            height={50}
                            width={50}
                        />
                    </div>
                </div>}
        </>
    )
}

export default UserProfile