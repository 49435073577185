import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import ArtistImg from '../../assets/images/artist.png'
import { Navigation } from 'swiper/modules';
import './PopularArtist.scss'
import { ViewAllIcon } from '../Icons';
import { Button } from 'react-bootstrap';
import Artist from '../Artist/Artist';
import { useDispatch, useSelector } from 'react-redux';
import useHeader from '../../Helper/useHeader';
import * as cs from '../../Api/cs'
import { useNavigate } from 'react-router-dom';
import { updateShowChat } from '../../Redux/Slices/chat.slice';
import { updateWalletShow } from '../../Redux/Slices/modal.slice';
import { updateIsBadgeInfo, updateOpenDirectly } from '../../Redux/Slices/badge.slice';


const PopularArtist = ({ genre = null }) => {
    const [artist, setArtist] = useState([])
    const headers = useHeader()
    const dispatch = useDispatch()

    const navigate = useNavigate()

    useEffect(() => {
        if (genre == null) {

            getPopularArtist()
        }
        else if (genre != null) {
            getPopularArtistOfGenre()
        }
    }, [])

    const getPopularArtist = async () => {
        const response = await cs.get('popular/artists', 'track', null, headers)
        setArtist(response?.data?.data)
        // const artists = response?.data?.data.map(item => item.artist);
        // setArtist(response?.data?.data.map(item => item.artist).flat());


    }

    const getPopularArtistOfGenre = async () => {
        const response = await cs.get('popularArtists/' + genre, 'genre', {}, headers)
        setArtist(response?.data?.data)
    }


    return (
        <>
            {artist && artist.length > 0 && <div className='popular-artist-wrapper'>
                <div className='main-wrapper'>
                    <div className='main-container'>
                        <div className='popular-artist-content'>
                            <div className='popular-artist-title-wrap'>
                                <div className='popular-artist-title'>Popular Artists</div>

                            </div>
                            <div className='popular-artist-slider-wrap'>
                                <div className='popular-artist-slider'>
                                    <Swiper
                                        spaceBetween={24}
                                        slidesPerView={4}
                                        modules={[Navigation]}

                                        breakpoints={{
                                            1600: {
                                                slidesPerView: 5,
                                                spaceBetween: 24
                                            },
                                            1400: {
                                                slidesPerView: 4,
                                                spaceBetween: 24
                                            },
                                            768: {
                                                slidesPerView: 3,
                                                spaceBetween: 24
                                            },
                                            300: {
                                                enabled: false,
                                                spaceBetween: 0
                                            }
                                        }}

                                    >
                                        {artist && artist?.length > 0 ?
                                            artist.map((item) => (
                                                <SwiperSlide key={item?.artistDetails?.[0]?._id} onClick={() => {
                                                    dispatch(updateShowChat(false)); dispatch(updateWalletShow(false)); dispatch(updateIsBadgeInfo(false)); dispatch(updateOpenDirectly(false));
                                                    navigate(`/profile/${item?.artistDetails?.[0]?._id}`, { state: { type: "user", id: item?.artistDetails?.[0]?._id } })
                                                }}>
                                                    <Artist artist={item?.artistDetails?.[0]} />
                                                </SwiperSlide>
                                            ))
                                            :
                                            <div>No Record Available!!</div>
                                        }


                                    </Swiper>
                                </div>
                                <div className='viewall-btn-wrap'
                                    onClick={() => {
                                        navigate(`/genre/${genre ? genre : 'all'}/popular-artist`, {
                                            state: {
                                                type: "artist",
                                                title: "Popular Artists",
                                                ...(genre !== null && { genre })
                                            },
                                        });
                                    }}
                                ><Button variant='secondary'><ViewAllIcon /> View all</Button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default PopularArtist