import React, { useEffect, useState, useRef } from 'react'
import Sidebar from '../../Component/Sidebar/Sidebar'
import CrownIcon from '../../assets/images/icons/crown.svg';
import { Button } from 'react-bootstrap';
import Header from '../../Component/Header/Header';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as cs from '../../Api/cs'
import useHeader from '../../Helper/useHeader';
import TrackToJoinBattle from '../../Modal/TrackToJoinBattle';
import BattleCard from '../../Component/BattelCard/BattleCard';
import Comment from '../Comment/Comment';
import { useDispatch, useSelector } from 'react-redux';
import CustomTextModal from '../../Modal/CustomTextModal';
import PlaceholderThumbImg from '../../assets/images/placeholder.png';
// import BattleLeftThumbImg from '../../assets/images/battle-left.png';
// import BattleRightThumbImg from '../../assets/images/battle-right.png';
import { ColoredStarIcon, FireIcon, HedphoneIcon, PauseIcon, PauseOutlineIcon, PlayIcon, PlayOutlineIcon, ShareIcon, StarIcon } from '../../Component/Icons';
import './BattleDetail.scss'
import { updateBattleId } from '../../Redux/Slices/battle.slice';
// import Player from '../../Component/Player/Player';
import { handleShowPlayer, updateArtistId, updateIsPlaying, updateTrack, updateTrackProgress } from '../../Redux/Slices/player.slice';
// import { async } from '@firebase/util';
import UserImg from '../../assets/images/default.png'
// import { CountrySelector } from 'react-international-phone';
import { BallTriangle } from 'react-loader-spinner';
import ConfirmationModal from '../../Modal/ConfirmationModal/ConfirmationModal';
import ReportModal from '../../Modal/ReportModal/ReportModal';
import ThanksModal from '../../Modal/ThanksModal/ThanksModal'
import Login from '../Login/Login';
import SignUp from '../SignUp/SignUp';
import OtpModal from '../../Modal/OtpModal';
import EmailLinkForPassword from '../EmailLinkForPassword/EmailLinkForPassword';
import SetUpAccount from '../../Modal/SetUpAccount/SetUpAccount';
import { updateUserData } from '../../Redux/Slices/user.slice';
import { updateSearch } from '../../Redux/Slices/search.slice';
// import { faBullseye } from '@fortawesome/free-solid-svg-icons';
// import moment from 'moment';
// import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { ShareButtons } from '../../Helper/ShareButtons';

import { capitalizeFirstLetter, updateDocumentTitle } from '../../Utils';

import { AnalyticsSendEvent } from '../../Helper/Analytics';

const BattleDetail = () => {
  const location = useLocation();

  const location_data = location


  const url = `https://beta.cntndr.io/#${location_data?.pathname}`
  const title = "CNTNDR BATTLE "
  // const { itemData } = location?.state;
  const headers = useHeader()
  const navigate = useNavigate()
  const { id } = useParams()
  const dispatch = useDispatch()




  const { battleId } = useSelector(state => state?.battle)




  const [battleOpponentStatus, setBattleOpponentStatus] = useState(false)
  const [showCloutModal, setShowCloutModal] = useState(false);
  const [showTrackModal, setShowTrackModal] = useState(false);
  const [entryFee, setEntryFee] = useState()
  const [userClout, setUserClout] = useState(700)
  const [battleEndDate, setBattleEndDate] = useState({})
  const [timer, setTimer] = useState(null)
  const [firstMember, setFirstMember] = useState({})
  const [secondMember, setSecondMember] = useState({})
  const [modalTitle, setModalTitle] = useState()
  const [modalBody, setModalBody] = useState()
  const [currentBattleIndex, setCurrentBattleIndex] = useState(0);
  const [sort, setSort] = useState('dsc')
  const [filter, setFilter] = useState('abcd')
  const [battles, setbattles] = useState([])
  const [voteInfo, setVoteInfo] = useState()
  const [battleDataId, setBattleDataId] = useState()
  // const [keyValue, setKeyValue] = useState('');
  const [trendingBattle, setTrendingBattle] = useState([])
  const [share, setShare] = useState(false)

  const handleTrackShow = () => setShowTrackModal(true);
  const handleTrackClose = () => setShowTrackModal(false);
  const [daysLeft, setDaysLeft] = useState(0);
  const [hoursLeft, setHoursLeft] = useState(0);
  const [minutesLeft, setMinutesLeft] = useState(0);
  const [secondsLeft, setSecondsLeft] = useState(0)
  const [battleData, setBattleData] = useState({})
  const [vote1, setVote1] = useState(false)
  const [vote2, setVote2] = useState(false)
  const [winner, setWinner] = useState({})
  const { userId, userInfo } = useSelector(state => state?.user);
  const [isVote1Clickable, setIsVote1Clickable] = useState(true);
  const [isVote2Clickable, setIsVote2Clickable] = useState(true);
  const { showPlayer } = useSelector(state => state.player)
  const [isFav, setIsFav] = useState(false)
  const [isPlayingFirstSong, setIsPlayingFirstSong] = useState(false);
  const [isPlayingSecondSong, setIsPlayingSecondSong] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reportModal, setReportModal] = useState(false)
  const [thanksModal, setThanksModal] = useState(false)
  const [showLogin, setShowLogin] = useState(false);
  const [showModal, setShowModel] = useState(false)
  const [emailLinkModal, setEmailLinkModal] = useState(false)
  const [showSignUp, setShowSignUp] = useState(false)
  const [showSetUp, setShowSetUp] = useState(false)
  const [user, setUser] = useState(null)
  const [_id, set_Id] = useState(null)
  const [token2, setToken2] = useState(null)
  const [confirm, setConfirm] = useState(false)
  const [danger, setDanger] = useState(null)


  const handleCloutShow = () => setShowCloutModal(true);
  const handleCloutClose = () => setShowCloutModal(false);
  const handleReportClose = () => setReportModal(false)
  const handleLoginClose = () => {
    setShowLogin(false)
  }
  const handleClose = () => {
    setShowModel(false)
  }
  const handleSignUpClose = () => {
    setShowSignUp(false)
  }

  const handleSetUpClose = () => {
    setShowSetUp(false)
  }
  const handleEmailLinkClose = () => {
    setEmailLinkModal(false)
  }
  const handleThanksClose = () => {
    setThanksModal(false)
  }
  const handleConfirmClose = () => {
    setConfirm(false)
  }
  const { search } = useSelector(state => state.search)


  const { isPlaying, track, artistId } = useSelector(state => state.player)

  useEffect(() => {
    // Reset isPlayingFirstSong and isPlayingSecondSong to false
    // getBattles()
    // getVoteDetails()
    // setIsPlayingFirstSong(false);
    // setIsPlayingSecondSong(false);
    scrollToTop()
    dispatch(updateBattleId(id))
    getBattleDetail(id)
    getBattles()
    getTrendingBattle()
    // ReactTooltip.rebuild();

    return () => {
      updateDocumentTitle('');
    }
  }, []);

  //For video 
  useEffect(() => {
    if (battleData?.trackDetails?.[0]?.track_video.endsWith('.mp4') || battleData?.trackDetails?.[0]?.track_video.endsWith('.mov')) {
      dispatch(handleShowPlayer(false));

    }
  }, [currentBattleIndex])


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    if (search) {
      dispatch(updateSearch(''))
    }
    setBattleDataId(battleId)
    getBattleDetail(id);
    favoriteCheck()
    getUserData()
    setIsPlayingFirstSong(false);
    setIsPlayingSecondSong(false);
  }, [battleId, id])

  useEffect(() => {
    getBattleDetail(id);
  }, [battleId, id, battleOpponentStatus]);

  const getUserData = async () => {
    const response = await cs.get("getUserList/" + userId, 'users', {}, headers)

    dispatch(updateUserData(response?.data?.data))
  }

  const getTrendingBattle = async () => {
    const response = await cs.get('trendingBattles', 'battel', null, headers)
    const filteredData = response?.data?.data?.filter(item => item?.battleData?._id !== id);
    setTrendingBattle(filteredData?.slice(0, 3))
  }






  const voteFreeze = (data) => {
    if (data?.battlecreatedBy === userId || data?.opponentcreatedBy === userId) {
      setIsVote1Clickable(false);
      setIsVote2Clickable(false);
    }
    else {
      setIsVote1Clickable(true)
      setIsVote2Clickable(true)
    }
  };

  const getBattles = async () => {
    // setLoading(true)
    const response = await cs.get('list/' + sort + "/" + filter, 'battel', {}, headers)
    setbattles(response?.data?.data)
  }


  /**
   * Add Battle Vote 
   */
  const addBattleVote = async (data) => {
    try {

      const response = await cs.post('add', 'battleVote', data, headers);
      if (response?.data?.code === 200) {
        AnalyticsSendEvent({
          category: "Battle",
          action: "battle_vote",
          label: "Battle Vote"
        });
        getVoteDetails(battleData)
        getUserData()
      }


    }
    catch (error) {
    }
  }


  useEffect(() => {
    if (artistId === firstMember?._id && isPlayingSecondSong) {
      setIsPlayingSecondSong(!isPlaying)
      setIsPlayingFirstSong(isPlaying);
    }
    else if (artistId === firstMember?._id) {
      // setIsPlayingSecondSong(!isPlaying)
      setIsPlayingFirstSong(isPlaying);
    }
    else if (artistId === secondMember?._id && isPlayingFirstSong) {
      setIsPlayingFirstSong(!isPlaying)
      setIsPlayingSecondSong(isPlaying);
    }
    else if (artistId === secondMember?._id) {
      // setIsPlayingFirstSong(!isPlaying)
      setIsPlayingSecondSong(isPlaying);
    }

  }, [isPlaying])

  useEffect(() => {
    let intervalId;
    let time

    if (battleEndDate) {
      const endDate = new Date(battleEndDate);

      const calculateTimeRemaining = () => {
        const currentTime = Date.now();
        time = endDate > currentTime ? endDate - currentTime : 0;


        setTimer(time)
        const hoursRemaining = time / (1000 * 60 * 60);
        setDanger(hoursRemaining)

        setDaysLeft(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHoursLeft(Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        setMinutesLeft(Math.floor((time % (1000 * 60 * 60)) / (1000 * 60)));
        setSecondsLeft(Math.floor((time % (1000 * 60)) / 1000));


      };


      calculateTimeRemaining();


      intervalId = setInterval(calculateTimeRemaining, 1000);
    }

    return () => clearInterval(intervalId);
  }, [battleOpponentStatus, battleEndDate]);


  useEffect(() => {
    if (timer === 0) {
      getWinner()

    }
  }, [timer])
  const [createAt, setCreateAt] = useState()


  const getBattleDetail = async (id) => {
    setLoading(true);
    try {
      if (id) {
        const response = await cs.get(`getById/${id}`, 'battel', {}, headers);

        if (response?.data?.code === 200) {
          const firstUser = response?.data?.data?.[0]?.battleUsersDetails?.[0];
          const secondUser = response?.data?.data?.[0]?.opponentUsersDetails?.[0];
          handleUpdatePageTitle(firstUser, secondUser)

          setCreateAt(response?.data?.data?.[0]?.createdAt)
          setBattleData(response?.data?.data?.[0]);
          getVoteDetails(response?.data?.data?.[0]);
          voteFreeze(response?.data?.data?.[0]);
          setWinner(response?.data?.data?.[0]);
          setFirstMember(firstUser);
          setBattleEndDate(response?.data?.data?.[0]?.battleEndDate);
          setBattleOpponentStatus(response?.data?.data?.[0]?.battleOpponentStatus);
          setEntryFee(response?.data?.data?.[0]?.battleEnteryFee);
          setSecondMember(secondUser);
          const index = battles?.findIndex(battle => battle?._id === id);
          setCurrentBattleIndex(index !== -1 ? index : 0);
          setLoading(false);
        } else {
          console.error("Failed to fetch battle details:", response?.data?.message);
        }
      }
    } catch (error) {
      console.error("Error fetching battle details:", error);
    } finally {
      setLoading(false);
    }
  };


  const handleUpdatePageTitle = (member1, member2) => {
    if (member1 && member2) {
      const firstUserName = capitalizeFirstLetter(member1.name)
      const secondUserName = capitalizeFirstLetter(member2.name)
      const title = `${firstUserName} vs. ${secondUserName} | CNTNDR`
      updateDocumentTitle(title);
    }
  }





  const handleJoinBattle = () => {

    if (userId === null) {
      setShowLogin(true)
    }

    else if (userId === firstMember?._id) {


      setModalTitle("You're Already In")
      setModalBody(`You're already a part of this battle. Wait for an opponent to join!`)
      handleCloutShow()
    }
    else if (entryFee > userInfo?.clout) {
      setModalTitle("Not Enough Clout")
      setModalBody("Oh no! You don't have enough Clout to participate in this battle. Go vote on other live battles to build up your Clout balance!")
      handleCloutShow()
    }
    else {


      handleTrackShow()
    }
  }




  /**
   * Get Vote Details ..
   */
  const getVoteDetails = async (battleInfo) => {
    try {
      const response = await cs.get(`vote/${userId}/${battleId}`, 'battleVote', {}, headers)
      if (response?.data?.code == 200) {

        setVoteInfo(response?.data?.data)
        setVotedDetails(response?.data?.data, battleInfo)

      }
    }
    catch (error) {

    }

  }


  const setVotedDetails = (data, battleInfo) => {


    if (data && data !== null) {
      if (data?.votefor == battleInfo?.battlecreatedBy && userId !== null) {

        setVote1(true)
        setVote2(false)
      }
      else if (data?.votefor == battleInfo?.opponentcreatedBy && userId !== null) {

        setVote2(true)
        setVote1(false)
      }
    }
    else {
      setVote1(false)
      setVote2(false)
    }

  }


  const goToPreviousBattle = () => {
    // dispatch(handleShowPlayer(false))
    if (currentBattleIndex > 0) {
      dispatch(handleShowPlayer(false));
      const newBattleIndex = currentBattleIndex - 1;
      setCurrentBattleIndex(newBattleIndex);
      const newBattleId = battles[newBattleIndex]?._id;
      dispatch(updateBattleId(newBattleId))
      navigate(`/battle/${newBattleId}`, { state: { itemData: newBattleId, battles: battles } });
    }
  };

  const goToNextBattle = () => {
    // dispatch(handleShowPlayer(false))
    if (currentBattleIndex < battles?.length - 1) {
      dispatch(handleShowPlayer(false));
      const newBattleIndex = currentBattleIndex + 1;
      setCurrentBattleIndex(newBattleIndex);
      const newBattleId = battles[newBattleIndex]?._id;
      dispatch(updateBattleId(newBattleId))
      navigate(`/battle/${newBattleId}`, { state: { itemData: newBattleId, battles: battles } });
    }
  };

  const handleFirstMem_Vote = async () => {

    if (vote1 || vote2) {
      setModalTitle('')
      setModalBody(`Oops! You can’t vote on a battle you already voted in! `)
      handleCloutShow()
      return;
    }
    if (!isVote1Clickable) {
      setModalTitle('')
      setModalBody(`Oops! You can’t vote on a battle you are participating in! `)
      handleCloutShow()
      return;
    }

    if (userId === null) {
      setShowLogin(true)
      return;
    }

    else if (userId !== null && !vote1 && !vote2) {
      setVote1(!vote1)
    }

    const data = {
      "voterId": userId,
      "battleId": battleId,
      "votefor": firstMember?._id
    }

    addBattleVote(data)


  }




  const handleSecondMem_Vote = async () => {

    if (vote1 || vote2) {
      setModalTitle('')
      setModalBody(`Oops! You can’t vote on a battle you already voted in! `)
      handleCloutShow()
      return;
    }
    if (!isVote2Clickable) {


      setModalTitle('')
      setModalBody(`Oops! You can’t vote on a battle you are participating in! `)
      handleCloutShow()
      return;

    }
    if (userId === null) {
      setShowLogin(true)
      return
    }

    else if (userId !== null && !vote1 && !vote2) {
      setVote2(!vote2)
    }

    const data = {
      "voterId": userId,
      "battleId": battleId,
      "votefor": secondMember?._id
    }

    addBattleVote(data)
  }

  const handlePlayer = (artistData, trackData) => {



    if (battleOpponentStatus === false) {
      setModalTitle("Join Battle!")
      setModalBody(`You can play the track only if you join`)
      handleCloutShow()
    }
    else if (battleOpponentStatus === true) {
      if (!showPlayer && artistData !== undefined) {
        dispatch(handleShowPlayer(true))

        if (!trackData || trackData?._id !== track?._id) {
          // If it's a new track, start playing from the beginning
          // setCurrentTrack(trackToBePlayed);
          dispatch(updateTrack(trackData));
          dispatch(updateTrackProgress(0));
          dispatch(updateArtistId(artistData?._id));

          if (!isPlaying) {
            dispatch(updateIsPlaying(true));
          }
          else if (isPlaying) {

            if (artistId === firstMember?._id && isPlayingSecondSong) {
              setIsPlayingSecondSong(false)
              setIsPlayingFirstSong(true);
            }
            else if (artistId === secondMember?._id && isPlayingFirstSong) {
              setIsPlayingFirstSong(false)
              setIsPlayingSecondSong(true);
            }

          }
        } else if (isPlaying) {
          // If it's the same track, toggle play/pause
          dispatch(updateIsPlaying(false));

        }
        else if (!isPlaying) {
          dispatch(updateIsPlaying(true));

        }
      }
      else if (artistData === undefined) {
        dispatch(handleShowPlayer(false))
      }
      else if (showPlayer && artistData !== undefined) {


        if (!trackData || trackData?._id !== track?._id) {
          // If it's a new track, start playing from the beginning
          // setCurrentTrack(trackToBePlayed);
          dispatch(updateTrack(trackData));
          dispatch(updateTrackProgress(0));
          dispatch(updateArtistId(artistData?._id));

          if (!isPlaying) {
            dispatch(updateIsPlaying(true));
          }

          else {


            if (artistData?._id == firstMember?._id && isPlayingSecondSong) {
              setIsPlayingSecondSong(false)
              setIsPlayingFirstSong(true);
            }
            else if (artistData?._id == secondMember?._id && isPlayingFirstSong) {
              setIsPlayingFirstSong(false)
              setIsPlayingSecondSong(true);
            }

          }

        } else if (isPlaying) {
          // If it's the same track, toggle play/pause
          dispatch(updateIsPlaying(false));

        }
        else if (!isPlaying) {
          dispatch(updateIsPlaying(true));
          // if(artistId === firstMember?._id)

        }
      }

    }

  }






  const getWinner = async () => {
    const response = await cs.get('battleWinner/' + battleId, 'battel', {}, headers)

    if (response?.status === 200) {
      setWinner(response?.data)
      getUserData()
    }
  }


  const addToFavorite = async () => {

    if (userId == null) {
      setShowLogin(true)
      return
    }

    const _data = {
      "battleId": battleId,
      "battleDescription": battleData?.battleDescription,
      "userId": userId

    }

    const response = await cs.post('add', 'battleMyFav', _data, headers)

    if (response?.data?.status === true) {
      setIsFav(true)
    }
    else if (response?.data?.status === false) {
      setIsFav(false)
    }
  }

  const favoriteCheck = async () => {
    const response = await cs.get('favoriteCheck/' + userId + "/" + battleId, "battleMyFav", {}, headers)
    if (response?.data?.value === true) {
      setIsFav(true)
    }
    else {
      setIsFav(false)
    }
  }


  const getFirstMemberPayPause = () => {

    if (isPlaying && isPlayingFirstSong) {
      return <PauseIcon />

    }
    else {
      return <PlayIcon />
    }
  }

  const getSecondMemberPayPause = () => {

    if (isPlaying && isPlayingSecondSong) {
      return <PauseIcon />

    }
    else {
      return <PlayIcon />
    }
  }

  const reportBattle = () => {
    if (userId === null) {
      // setReportLoginModal(true)d
      setShowLogin(true)
    }
    else if (userId !== null) {
      setReportModal(true)
    }

  }

  const handleBattleCard = (item) => {
    navigate(`/battle/${item}`, { state: { itemData: item, battles: trendingBattle } })
    dispatch(updateBattleId(item))
  }
  // For video 
  const firstVideoRef = useRef(null);
  const secondVideoRef = useRef(null);

  const handleFirstVideoPlay = () => {
    firstVideoRef.current.classList.add('playing');
    if (secondVideoRef.current) {
      secondVideoRef.current.pause();
    }
    if (battleOpponentStatus === false) {
      setModalTitle("Join Battle!")
      setModalBody(`You can play the track only if you join`)
      firstVideoRef.current.pause();
      handleCloutShow()
    }
  };

  const handleSecondVideoPlay = () => {
    secondVideoRef.current.classList.add('playing');
    if (firstVideoRef.current) {
      firstVideoRef.current.pause();
    }
    if (battleOpponentStatus === false) {
      setModalTitle("Join Battle!")
      setModalBody(`You can play the track only if you join`)
      secondVideoRef.current.pause();
      handleCloutShow()
    }
  };

  // for showing the timer of opponent  

  const calculateTimeRemaining = (createAt) => {
    // let intervalId;

    const daysInHours = 72;
    const endTime = new Date(new Date(createAt).getTime() + daysInHours * 60 * 60 * 1000);
    const now = new Date();
    const timeDiff = endTime - now;

    if (timeDiff <= 0) {
      return { hours: '00', minutes: '00', seconds: '00' };
    }

    const currentTime = Date.now();
    let time = endTime > currentTime ? endTime - currentTime : 0;

    const days = Math.floor((time / (1000 * 60 * 60 * 24)));
    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return {
      days: String(days).padStart(2, '0'),
      hours: String(hours).padStart(2, '0'),
      minutes: String(minutes).padStart(2, '0'),
      seconds: String(seconds).padStart(2, '0'),
    };
  };
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining(createAt));


  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(createAt));
    });

    return () => clearInterval(interval);
  }, [createAt]);

  // opponent join timer end here 



  return (
    <>


      <Header />
      <Sidebar />
      {!loading ?
        <>

          <div className='battle-main-container'>

            <div className='battle-snapshot-wrapper'>
              <div className='battle-snapshot-left'>
                <img src={firstMember?.image ? firstMember?.image : UserImg} />
              </div>

              {battleOpponentStatus ? <div className='battle-snapshot-right'>
                <img src={secondMember?.image ? secondMember?.image : UserImg} />
              </div> :
                <div className='battle-wating-snapshot'> </div>

              }

            </div>

            <div className='main-wrapper'>
              <div className='main-container'>
                <div className='battle-container-wrapper'>
                  <div className='battle-top-row'>

                    <div className='genres-wrap'>
                      <div className='genres-tag' onClick={() => navigate(`/genre/${battleData?.battleTrackGenre?.[0]?._id}`, { state: { genre: battleData?.battleTrackGenre?.[0]?._id } })}>{battleData?.battleTrackGenre?.[0]?.label}</div>
                    </div>
                    <div className='icon-wrap-top'>
                      <div className='icon-wrap' onClick={addToFavorite}>{isFav ? <ColoredStarIcon /> : <StarIcon />}</div>
                      <div className='icon-wrap' onClick={() => setShare(!share)}>
                        {share && <div className="share-buttons">
                          <ShareButtons url={url} title={title} />
                        </div>
                        }
                        <div><ShareIcon /></div></div>


                    </div>
                  </div>
                  <div className='battle-container'>

                    <div className={`battle-box  ${(vote1 || winner?.winnerId?._id === firstMember?._id) && 'active'}`}>

                      {/* <div className='battle-img-wrap'>
                        <img src={firstMember?.image ? firstMember?.image : UserImg} alt='User' />
                        <div className='icon-wrap' onClick={() => { handlePlayer(firstMember, battleData?.trackDetails?.[0], 'first') }}>
                          {getFirstMemberPayPause()}

                        </div>
                      </div> */}
                      {/* For video  */}
                      <div className='battle-img-wrap'>
                        {battleData?.trackDetails?.[0]?.track_video.endsWith('.mp4') || battleData?.trackDetails?.[0]?.track_video.endsWith('.mov') ? (
                          <video
                            ref={firstVideoRef}
                            src={battleData?.trackDetails?.[0]?.track_video}
                            controls
                            onPlay={handleFirstVideoPlay}
                            controlsList="nodownload noplaybackrate"
                            disablePictureInPicture
                            className="custom-video-player"
                          />
                        ) : (
                          <>   <img src={firstMember?.image ? firstMember?.image : UserImg} alt='User' />
                            <div className='icon-wrap' onClick={() => { handlePlayer(firstMember, battleData?.trackDetails?.[0], 'first') }}>
                              {getFirstMemberPayPause()}
                            </div>
                          </>

                        )}

                      </div>
                      {/* battleData?.isDeleted == false >> this is for not showing winner if opponent not join battle with in 24 hour  */}
                      {timer === 0 && winner && winner?.winnerId?._id === firstMember?._id && battleData?.isDeleted == false &&
                        <>
                          <div className='winner-wrap'>
                            <span className='winner-tag'><img src={CrownIcon} alt='Crown' /> Winner</span>
                          </div>
                          <div className='battle-authur-detail prize'>

                            <div className='battle-authur-detail-wrap '>


                              <div className='battle-authur-song-title'>Prize Won</div>
                              <div className='battle-authur-song-play'>{winner?.battleEnteryFee * 2} Clout</div>

                            </div>

                          </div>
                        </>
                      }
                      {timer === 0 && winner && winner?.winnerId?._id && !battleData?.isTie &&
                        (winner?.winnerId?._id == firstMember?._id ? (
                          <div className='battle-authur-detail'>
                            <div className='battle-authur-detail-wrap'>
                              <div className='battle-authur-song-title'>
                                <span>Vote Received</span>
                                <span>{winner?.winnerVotePercentage?.toFixed(2)}%</span>
                              </div>
                            </div>
                          </div>

                        ) : (
                          <div className='battle-authur-detail'>
                            <div className='battle-authur-detail-wrap'>
                              <div className='battle-authur-song-title'>
                                <span>Vote Received</span>
                                <span>{winner?.looserVotePercentage?.toFixed(2)}%</span>
                              </div>
                            </div>
                          </div>
                        ))}
                      {timer === 0 && battleData?.isTie &&
                        <>
                          <div className='Tied-wrap'>
                            <p>Tied</p>
                          </div>
                          <div className='battle-authur-detail'>
                            <div className='battle-authur-detail-wrap'>
                              <div className='battle-authur-song-title'>
                                <span>Vote Received</span>
                                <span>50%</span>
                              </div>
                            </div>
                          </div>
                        </>

                      }

                      <div className='battle-authur-detail'>
                        <div className='battle-authur-detail-wrap loser'>
                          <div className='battle-authur-title' onClick={() => { navigate(`/profile/${firstMember?._id}`, { state: { type: "user", id: firstMember?._id } }) }}>{firstMember?.name ? firstMember?.name : firstMember?.userName}</div>
                          {battleOpponentStatus && <div className='battle-authur-song-title cursor-pointer' onClick={() => { navigate(`/track/${battleData?.trackDetails?.[0]?._id}`, { state: { trackId: battleData?.trackDetails?.[0]?._id } }) }}>{battleData?.trackDetails?.[0]?.track_name}</div>}
                        </div>
                        {/* plays is hiding because of justin  */}
                        {/* {battleOpponentStatus && <div className='battle-authur-song-play'>{battleData?.trackDetails?.[0]?.track_count} plays</div>} */}
                      </div>

                      {battleOpponentStatus &&
                        <div className='battle-description' data-tooltip-content={battleData?.battleDescription} data-tooltip-id='my-tooltip' >
                          {battleData?.battleDescription}
                        </div>
                      }
                      {!battleOpponentStatus && userId == firstMember?._id && <div className='btn-wrap mt-3'><Button variant='secondary' onClick={() => { setConfirm(true) }} >Withdraw</Button></div>}
                    </div>

                    <div className='battle-vote-wrapper'>
                      {!battleOpponentStatus ? (
                        new Date(battleEndDate) > Date.now() || battleEndDate == null ? (
                          <>
                            {/* // for showing the timer of opponent  */}
                            <div className='battle-vote-wrap'>
                              {timeRemaining.hours != "00" && timeRemaining.minutes != "00" && timeRemaining.seconds != "00" &&
                                <div className='timmer-wrapper'>
                                  <div className='time-remaining-title'>{!battleOpponentStatus && userId == firstMember?._id ? 'Challenge expires in :' : 'Expires in :'}</div>
                                  <div className={`time-counter-wrapper ${timeRemaining.danger ? 'danger' : ''}`}>
                                    {timeRemaining.days !== '00' && <>
                                      <div className='time-wrap'>
                                        <div className='time-counter'>{timeRemaining.days}</div>
                                        <div className='time-format'>{timeRemaining.days <= 1 ? 'Day' : 'Days'}</div>
                                      </div>
                                      <div className='time-divider'>:</div>
                                    </>}
                                    <div className='time-wrap'>
                                      <div className='time-counter'>{timeRemaining.hours}</div>
                                      <div className='time-format'>{timeRemaining.hours <= 1 ? 'Hour' : 'Hours'}</div>
                                    </div>
                                    <div className='time-divider'>:</div>
                                    <div className='time-wrap'>
                                      <div className='time-counter'>{timeRemaining.minutes}</div>
                                      <div className='time-format'>{timeRemaining.minutes <= 1 ? 'Minute' : 'Minutes'}</div>
                                    </div>
                                    {timeRemaining.days === '00' &&
                                      <>
                                        <div className='time-divider'>:</div>
                                        <div className='time-wrap'>
                                          <div className='time-counter'>{timeRemaining.seconds}</div>
                                          <div className='time-format'>{timeRemaining.seconds <= 1 ? 'Second' : 'Seconds'}</div>
                                        </div>
                                      </>}
                                  </div>
                                </div>
                              }
                              <div className='icon-wrap'><HedphoneIcon /></div>

                              <div className='battle-vote-message'>
                                {timeRemaining.hours == "00" && timeRemaining.minutes == "00" && timeRemaining.seconds == "00" ? "CHALLENGE EXPIRED" : "AWAITING A CHALLENGER"}
                              </div>
                            </div>
                          </>
                        ) : (
                          new Date(battleEndDate) < Date.now() && (
                            <div className='battle-vote-wrap'>
                              <div className='icon-wrap'><HedphoneIcon /></div>
                              <div className='battle-vote-message'>Finished</div>
                            </div>
                          )
                        )


                      ) : new Date(battleEndDate) > Date.now() ? (
                        <>
                          <div className='battle-vote'>
                            {vote1 ? <div className='vote-wrapper ' onClick={handleFirstMem_Vote}>VOTED</div> :
                              <div className={`vote-wrapper ${vote2 && 'active'}`} onClick={handleFirstMem_Vote}>VOTE</div>}
                            <div className='icon-wrap'><HedphoneIcon /></div>
                            {vote2 ? <div className='vote-wrapper' onClick={handleSecondMem_Vote}>VOTED</div> :
                              <div className={`vote-wrapper ${vote1 && 'active'}`} onClick={handleSecondMem_Vote}>VOTE</div>}
                          </div>
                        </>
                      ) : (
                        new Date(battleEndDate) < Date.now() && (
                          <div className='battle-vote-wrap'>
                            <div className='icon-wrap'><HedphoneIcon /></div>
                            <div className='battle-vote-message'>Finished</div>
                          </div>
                        )
                      )}

                      {
                        battleOpponentStatus && new Date(battleEndDate) > Date.now() &&

                        <div className='timmer-wrapper'>
                          <div className='time-remaining-title'>Time remaining:</div>
                          <div className={`time-counter-wrapper ${danger <= 1 ? 'danger' : ''}`}>
                            {daysLeft >= 1 && <div className='time-wrap'>
                              <div className='time-counter'>{daysLeft}</div>
                              <div className='time-format'>{(daysLeft <= 1) ? "Day" : "Days"}</div>
                            </div>
                            }
                            {daysLeft >= 1 && <div className='time-divider'>:</div>}
                            <div className='time-wrap'>
                              <div className='time-counter'>{hoursLeft}</div>
                              <div className='time-format'>{(hoursLeft <= 1) ? "Hour" : "Hours"}</div>
                            </div>
                            <div className='time-divider'>:</div>
                            <div className='time-wrap'>
                              <div className='time-counter'>{minutesLeft}</div>
                              <div className='time-format'>{(minutesLeft <= 1) ? "Minute" : "Minutes"}</div>
                            </div>
                            {daysLeft < 1 && <div className='time-divider'>:</div>}
                            {daysLeft < 1 && <div className='time-wrap'>
                              <div className='time-counter'>{secondsLeft}</div>
                              <div className='time-format'>{(secondsLeft <= 1) ? "Second" : "Seconds"}</div>
                            </div>
                            }
                          </div>
                        </div>
                      }
                    </div>
                    <div className={`battle-box ${(vote2 || winner?.winnerId?._id === secondMember?._id) && 'active'}`} >


                      {battleOpponentStatus ?
                        <>
                          {/* <div className='battle-img-wrap'>
                            <img src={secondMember?.image ? secondMember?.image : UserImg} alt='User' />
                            <div className='icon-wrap' onClick={() => { handlePlayer(secondMember, battleData?.opponentTrackDetails?.[0]) }}>
                              {getSecondMemberPayPause()}

                            </div>
                          </div> */}

                          {/* for video  */}
                          <div className='battle-img-wrap'>
                            {battleData?.trackDetails?.[0]?.track_video.endsWith('.mp4') || battleData?.trackDetails?.[0]?.track_video.endsWith('.mov') ? (
                              <video
                                ref={secondVideoRef}
                                src={battleData?.opponentTrackDetails?.[0]?.track_video}
                                controls
                                onPlay={handleSecondVideoPlay}
                                controlsList="nodownload noplaybackrate"
                                disablePictureInPicture
                                className="custom-video-player"
                              />
                            ) : (
                              <>   <img src={secondMember?.image ? secondMember?.image : UserImg} alt='User' />
                                <div className='icon-wrap' onClick={() => { handlePlayer(secondMember, battleData?.opponentTrackDetails?.[0]) }}>
                                  {getSecondMemberPayPause()}
                                </div>
                              </>

                            )}

                          </div>
                          {/* <div className='battle-authur-detail'>
                            <div className='battle-authur-detail-wrap'> */}
                          {timer === 0 && winner && winner?.winnerId?._id === secondMember?._id &&
                            <>
                              <div className='winner-wrap'>
                                <span className='winner-tag'><img src={CrownIcon} alt='Crown' /> Winner</span>
                              </div>
                              <div className='battle-authur-detail prize'>

                                <div className='battle-authur-detail-wrap '>


                                  <div className='battle-authur-song-title'>Prize Won  </div>
                                  <div className='battle-authur-song-play'>{winner?.battleEnteryFee * 2} Clout</div>

                                </div>

                              </div>
                            </>
                          }
                          {timer === 0 && winner && winner?.winnerId?._id && !battleData?.isTie &&
                            (winner?.winnerId?._id == secondMember?._id ? (

                              <div className='battle-authur-detail'>
                                <div className='battle-authur-detail-wrap'>
                                  <div className='battle-authur-song-title'>
                                    <span>Vote Received</span>
                                    <span>{winner?.winnerVotePercentage?.toFixed(2)}%</span>
                                  </div>
                                </div>
                              </div>
                            ) : (

                              <div className='battle-authur-detail'>
                                <div className='battle-authur-detail-wrap'>
                                  <div className='battle-authur-song-title'>
                                    <span>Vote Received</span>
                                    <span>{winner?.looserVotePercentage?.toFixed(2)}%</span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          {timer === 0 && battleData?.isTie &&

                            <>
                              <div className='Tied-wrap'>
                                <p>Tied</p>
                              </div>
                              <div className='battle-authur-detail'>
                                <div className='battle-authur-detail-wrap'>
                                  <div className='battle-authur-song-title'>
                                    <span>Vote Received : </span>
                                    <span>50%</span>

                                  </div>
                                </div>
                              </div>
                            </>

                          }
                          {/* </div>
                          </div> */}
                          <div className='battle-authur-detail'>
                            <div className='battle-authur-detail-wrap loser'>
                              <div className='battle-authur-title' onClick={() => { navigate(`/profile/${secondMember?._id}`, { state: { type: "user", id: secondMember?._id } }) }}>{secondMember?.name ? secondMember?.name : secondMember?.userName}</div>
                              {battleOpponentStatus && <div className='battle-authur-song-title cursor-pointer' onClick={() => { navigate(`/track/${battleData?.opponentTrackDetails?.[0]?._id}`, { state: { trackId: battleData?.opponentTrackDetails?.[0]?._id } }) }}>{battleData?.opponentTrackDetails?.[0]?.track_name}</div>}

                            </div>
                            {/* plays is hiding because of justin  */}
                            {/* {battleOpponentStatus && <div className='battle-authur-song-play'>{battleData?.opponentTrackDetails?.[0]?.track_count} plays</div>} */}
                          </div>
                          <div className='battle-description' data-tooltip-content={battleData?.opponentTrackDetails?.[0]?.track_description} data-tooltip-id='my-tooltip'>
                            {battleData?.opponentTrackDetails?.[0]?.track_description}
                            {/* Lorem ipsum dolor sit amet consectetur. Mattis morbi eget tristique feugiat risus tempus maecenas. Tempor euismod... */}
                          </div>
                        </>
                        :
                        <>
                          <div className='battle-img-wrap'>
                            <img src={PlaceholderThumbImg} alt='User' />
                            <div className='looking-battle-text'>
                              <div className='w-100 text-center'>This battle is still waiting for an opponent.</div>
                              <div className='w-100 text-center'>Would you like to join?</div>
                            </div>
                          </div>
                          <div className='w-100 mt-2'>
                            <Button variant='primary' onClick={(event) => { event.stopPropagation(); handleJoinBattle() }} disabled={timeRemaining.hours === "00" && timeRemaining.minutes === "00" && timeRemaining.seconds === "00"}>{timeRemaining.hours == "00" && timeRemaining.minutes == "00" && timeRemaining.seconds == "00" ? "Request Rematch" : "Join Battle"}</Button>
                          </div>
                          {(timeRemaining.hours !== "00" && timeRemaining.minutes !== "00" && timeRemaining.seconds !== "00") && (
                            <div className='w-100 enter-fee-wrap mt-4'>
                              <div className='enter-fee-title'>Entry Fee :</div>
                              <div className='enter-fee'>{entryFee} Clout</div>
                            </div>
                          )}

                        </>
                      }
                    </div>
                  </div>
                  <div className='battle-bottom-nav'>
                    <div className='battle-bottom-nav-link'>
                      <span onClick={goToPreviousBattle} disabled={currentBattleIndex === 0}>{currentBattleIndex === 0 ? "" : "Previous"} </span>
                    </div>
                    <div className='battle-bottom-nav-link report' onClick={reportBattle}>
                      <span>Report a Battle</span>
                    </div>
                    <div className='battle-bottom-nav-link'>
                      <span onClick={goToNextBattle} disabled={currentBattleIndex === battles?.length - 1}>{currentBattleIndex === battles.length - 1 ? "" : "Go to the Next"} </span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>



          <div className='battle-detail-wrapper'>
            <div className='main-wrapper'>
              <div className='main-container'>


                {
                  battleOpponentStatus &&
                  <div className='battle-detail-container'>
                    <div className='battle-comment-container'>
                      <Comment type="battle" id={id} setModalTitle={setModalTitle} setModalBody={setModalBody} handleCloutShow={handleCloutShow} />
                    </div>
                    <div className='more-battle-wrapper'>
                      {trendingBattle && trendingBattle?.length > 0 && <div className='more-battle-title'>More battles</div>}
                      {trendingBattle && trendingBattle?.length > 0 && trendingBattle?.map((item) => (
                        <div className='w-100' key={item?.battleData?._id} onClick={() => { handleBattleCard(item?.battleData?._id) }}>
                          <BattleCard battleId={item?.battleData?._id} trending={true} type={item?.battleOpponentStatus ? null : 'awaiting'} />
                        </div>
                      ))}

                    </div>
                  </div>
                }
              </div>
            </div>
          </div>

        </>
        :   // Show loader if loading state is true
        <> <div className="loader-container">
          <div className="loader">
            <BallTriangle
              type="TailSpin"
              color="#00BFFF"
              height={50}
              width={50}
            />
          </div>
        </div>
        </>

        // <div className="centered-div">That challenge has expired.</div>

      }


      {showCloutModal && <CustomTextModal showModal={showCloutModal} handleClose={handleCloutClose} title={modalTitle} body={modalBody} />}
      {showTrackModal && <TrackToJoinBattle showModal={showTrackModal} handleClose={handleTrackClose} setBattleOpponentStatus={setBattleOpponentStatus} genre={battleData?.battleTrackGenre} />}
      {/* {reportLoginModel && <ReportLoginModal showModal={reportLoginModel} handleClose={handleReportLoginClose} />} */}
      {reportModal && <ReportModal showModal={reportModal} handleClose={handleReportClose} setThanksModal={setThanksModal} type="battle" />}
      {thanksModal && <ThanksModal showModal={thanksModal} handleClose={handleThanksClose} />}
      {showLogin && <Login showLogin={showLogin} handleLoginClose={handleLoginClose} setShowLogin={setShowLogin} setShowModel={setShowModel} setEmailLinkModal={setEmailLinkModal} setShowSignUp={setShowSignUp} setUser={setUser} setShowSetUp={setShowSetUp} setId={set_Id} setToken2={setToken2} />}
      {showSignUp && <SignUp showSignUp={showSignUp} handleSignUpClose={handleSignUpClose} setShowSignUp={setShowSignUp} setShowLogin={setShowLogin} setShowModel={setShowModel} setUser={setUser} user={user} setId={set_Id} setToken2={setToken2} />}
      {showModal && <OtpModal showModal={showModal} handleClose={handleClose} setShowModel={setShowModel} user={user} setShowLogin={setShowLogin} setShowSetUp={setShowSetUp} id={_id} token2={token2} />}
      {emailLinkModal && <EmailLinkForPassword emailLinkModal={emailLinkModal} handleEmailLinkClose={handleEmailLinkClose} setShowLogin={setShowLogin} />}
      {showSetUp && <SetUpAccount showSetUp={showSetUp} handleSetUpClose={handleSetUpClose} />}
      {confirm && <ConfirmationModal showModal={confirm} handleClose={handleConfirmClose} id={battleId} type="battle" />}
    </>

  )
}

export default BattleDetail
